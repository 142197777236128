import {
    AssignedBrother,
    Assignment,
    AssignmentCommands,
    Command,
    ExchangeBrotherCommandData,
    Nomination,
    RevokeBrotherCommandData,
} from "@cos/core";
import { Button } from "primereact/button";
import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { AssignBrotherDialog } from "./AssignBrother";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { assignmentService } from "../../services/assignment";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { ExchangeDialog } from "./ExchangeDialog";
import { nominationService } from "../../services/nomination";

type AssignedToTableProps = {
    assignment: Assignment | null;
    data: AssignedBrother[];
    scheduleId: string;
    onChanged: () => void;
};

const AssignedToTable = (props: AssignedToTableProps) => {
    const [addDialogVisibile, setAddDialogVisisble] = useState(false);
    const [visible, setVisible] = useState(false);
    const [nominations, setNominations] = useState<Nomination[]>();
    const [data, setData] = useState<AssignedBrother>();

    const fetchData = async () => {
        const nominations = await nominationService.findByScheduleId(props.scheduleId);

        setNominations(nominations);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleDelete = row => {
        confirmRemove(row);
    };

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RevokeBrotherCommandData> = {
                type: AssignmentCommands.RevokeBrother,
                data: {
                    assignmentId: props.assignment?.assignmentId || "",
                    brotherId: row.brother.brotherId,
                },
            };
            const result = await assignmentService.revokeBrother(cmd);
            if (result.data.success) {
                props.onChanged();
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du die Zuteilung an '${row.brother.firstname} ${row.brother.lastname}' löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ExchangeBrotherCommandData> = {
            type: AssignmentCommands.ExchangeBrother,
            data: {
                assignmentId: props.assignment?.assignmentId || "",
                brotherId: e.data.brother?.brotherId,
                newBrotherId: e.data.brother?.brotherId,
                nominationId: e.data.nomination?.nominationId,
                text: newData.text,
            },
        };

        const response = await assignmentService.exchangeBrother(cmd);

        if (response.data?.success) {
            props.onChanged();
        }
    };
    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const handleHide = async () => {
        props.onChanged();
        setAddDialogVisisble(false);
    };

    const tableHeader = (
        <div className="flex flex-row w-full justify-content-between align-items-center">
            {`Zuteilungen`}
            <div className="flex flex-row justify-content-end">
                <Button
                    icon="pi pi-user-plus mr-2 text-2xl font-bold"
                    className="p-button-text"
                    onClick={() => setAddDialogVisisble(true)}
                />
                <AssignBrotherDialog
                    visible={addDialogVisibile}
                    assignment={props.assignment}
                    scheduleId={props.scheduleId}
                    onHide={handleHide}
                />
            </div>
        </div>
    );

    return (
        <div className="flex flex-column p-1 surface-50">
            <ConfirmDialog />
            <ExchangeDialog
                brotherId={data?.brother.brotherId || ""}
                assignment={props.assignment as Assignment}
                nominations={nominations?.filter(y => y.brother.brotherId !== data?.brother.brotherId)}
                visible={visible}
                onHide={() => {
                    setVisible(false);
                    props.onChanged();
                }}
            ></ExchangeDialog>
            <DataTable
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                value={props.data.filter(y => y.brother.brotherId !== null)}
                header={tableHeader}
                paginator
                size="small"
                rows={10}
                emptyMessage="Keine Zuteilungen"
            >
                <Column field="brother.lastname" header="Name" sortable></Column>
                <Column field="brother.firstname" header="Vorname" sortable></Column>
                <Column field="text" header="Bemerkung" editor={options => textEditor(options)} sortable></Column>
                <Column
                    rowEditor={true}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    body={row => (
                        <div className="flex flex-row">
                            <Button
                                icon="pi pi-users text-2xl font-bold"
                                className=" p-button-text mr-3"
                                onClick={() => {
                                    setData(row);
                                    setVisible(true);
                                }}
                            />
                            <Button
                                icon="pi pi-user-minus text-2xl font-bold"
                                className="p-button-text"
                                onClick={() => handleDelete(row)}
                            />
                        </div>
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </div>
    );
};

export { AssignedToTable };
