import { useParams } from "react-router-dom";
import { AssignedToTable } from "./AssignedToTable";
import { Assignment, AssignmentCommands, ChangeAssignmentCommandData, Command } from "@cos/core";
import { useEffect, useState } from "react";
import { assignmentService } from "../../services/assignment";
import { Button } from "primereact/button";

const AssignedToPage = () => {
    const params = useParams();

    const [data, setData] = useState<Assignment | null>(null);
    const handleChanged = () => {
        const fetchData = async () => {
            const assignment = await assignmentService.findById(params.assignmentId || "");
            if (assignment) {
                setData(assignment);
            }
        };
        fetchData();
    };

    useEffect(() => {
        const fetchData = async () => {
            const assignment = await assignmentService.findById(params.assignmentId || "");
            if (assignment) {
                setData(assignment);
            }
        };
        fetchData();
    }, [params.assignmentId]);

    const handleIncrement = async () => {
        const cmd: Command<ChangeAssignmentCommandData> = {
            type: AssignmentCommands.ChangeAssignment,
            data: {
                assignmentId: data?.assignmentId || "",
                start: data?.start || "",
                end: data?.end || "",
                min: data?.min || 1,
                max: (data?.max || 1) + 1,
                schedulePartId: data?.part?.schedulePartId || "",
            },
        };

        await assignmentService.changeAssignment(cmd);
        handleChanged();
    };

    const handleDecrement = async () => {
        const cmd: Command<ChangeAssignmentCommandData> = {
            type: AssignmentCommands.ChangeAssignment,
            data: {
                assignmentId: data?.assignmentId || "",
                start: data?.start || "",
                end: data?.end || "",
                min: data?.min || 1,
                max: (data?.max || 1) - 1,
                schedulePartId: data?.part?.schedulePartId || "",
            },
        };

        await assignmentService.changeAssignment(cmd);
        handleChanged();
    };

    return (
        <div className="flex flex-column">
            <div className="flex flex-row font-bold surface-50 align-items-center">
                <div className="mr-4">
                    Anzahl: {data?.min} / {data?.max}
                </div>
                <Button icon="pi pi-plus" className="p-button-text text-2xl font-bold" onClick={handleIncrement} />
                <Button icon="pi pi-minus" className="p-button-text text-2xl font-bold" onClick={handleDecrement} />
            </div>
            <AssignedToTable
                data={data?.assignedTo || []}
                assignment={data}
                scheduleId={params.scheduleId || ""}
                onChanged={handleChanged}
            />
        </div>
    );
};

export { AssignedToPage };
