import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
    AddNewNominationItemCommandData,
    ChangeNominationItemCommandData,
    Command,
    NominationItem,
    NominationItemCommands,
    RemoveNominationItemCommandData,
} from "@cos/core";

import { nominationItemService } from "../../../services/nominationItemService";
import { AddNewNominationItemDialog } from "./AddNewNominationItem";

type propsType = {
    nominationItems: NominationItem[];
    allowEdit: boolean;
    onChanged: (nominationItem: NominationItem) => void;
};

const NominationItemsTable = (props: propsType) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(props.nominationItems);

    useEffect(() => {
        setData(props.nominationItems);
    }, [props.nominationItems]);

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeNominationItemCommandData> = {
            type: NominationItemCommands.ChangeNominationItem,
            data: {
                nominationItemId: newData.nominationItemId,
                shortName: newData.shortName,
                name: newData.name,
            },
        };

        const response = await nominationItemService.changeNominationItem(cmd);

        if (response.data.success) {
            props.onChanged(newData as NominationItem);
        }
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const handleSubmit = async nominationItem => {
        const cmd: Command<AddNewNominationItemCommandData> = {
            type: NominationItemCommands.AddNewNominationItem,
            data: { ...nominationItem },
        };

        const result = await nominationItemService.addNewNominationItem(cmd);
        if (result.data?.success) {
            const newData = [...data, nominationItem];
            setData(newData);
        }
        setVisible(false);
    };

    const handleOnHide = () => {
        setVisible(!visible);
    };

    const handleOnDelete = row => {
        confirmRemove(row);
    };

    const headerTemplate = (
        <div className="flex justify-content-end align-content-center">
            <ConfirmDialog />
            <AddNewNominationItemDialog visible={visible} onHide={handleOnHide} onSubmit={handleSubmit} />
            <Button label="Neu" icon="pi pi-plus" className="p-button-link" onClick={handleOnHide} />
        </div>
    );

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveNominationItemCommandData> = {
                type: NominationItemCommands.RemoveNominationItem,
                data: { nominationItemId: row.nominationItemId },
            };

            const result = await nominationItemService.removeNominationItem(cmd);
            if (result.data?.success) {
                const newData = data.filter(y => y.nominationItemId != row.nominationItemId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du "${row.name}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };
    return (
        <DataTable
            value={data}
            size={"small"}
            rows={20}
            className="w-full"
            editMode="row"
            onRowEditComplete={handleRowEditCompete}
            dataKey="nominationItemId"
            sortField="name"
            sortOrder={-1}
            header={headerTemplate}
            paginator
        >
            <Column
                field="name"
                className="w-full"
                editor={options => textEditor(options)}
                header="Ernennung"
                sortable
            ></Column>
            <Column
                field="shortName"
                className="w-full"
                editor={options => textEditor(options)}
                header="Kurz"
                sortable
            ></Column>
            <Column
                rowEditor={props.allowEdit}
                headerStyle={{ width: "10%", minWidth: "8rem" }}
                bodyStyle={{ textAlign: "center" }}
            ></Column>
            <Column
                body={row => (
                    <Button
                        icon="pi pi-trash"
                        className="p-button-danger p-button-link"
                        onClick={() => handleOnDelete(row)}
                    />
                )}
                bodyStyle={{ textAlign: "center" }}
            ></Column>
        </DataTable>
    );
};

export { NominationItemsTable };
