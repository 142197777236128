import { useEffect, useState } from "react";
import { scheduleService } from "../../services/schedule";
import { AssignBrotherCommandData, Assignment, AssignmentCommands, Brother, Command, Schedule } from "@cos/core";
import { SchedulePanel } from "../schedules/SchedulePanel";
import { Accordion, AccordionTab } from "primereact/accordion";
import { addDays, addMonths, endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";

import { SplitButton } from "primereact/splitbutton";
import { MenuItem } from "primereact/menuitem";
import { assignmentService } from "../../services/assignment";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const Homepage = () => {
    const user: Brother = JSON.parse(localStorage.getItem("user") || "");
    const from = startOfDay(Date.now());
    const to = endOfMonth(endOfDay(addDays(Date.now(), 90)));
    const [schedules, setSchedules] = useState<Schedule[]>();
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>([from, to]);

    const fetchData = async () => {
        const schedules = await scheduleService.findAll();
        setSchedules(schedules);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSetToday = () => {
        const startDate = startOfDay(Date.now());
        const endDate = endOfDay(Date.now());
        setDates([startDate, endDate]);
    };

    const handleSetCurrentMonth = () => {
        const startDate = startOfDay(Date.now());
        const endDate = endOfDay(endOfMonth(Date.now()));
        setDates([startDate, endDate]);
    };

    const handleSetNextMonth = () => {
        const startDate = startOfDay(startOfMonth(addMonths(Date.now(), 1)));
        const endDate = endOfDay(endOfMonth(startDate));
        setDates([startDate, endDate]);
    };

    const calendarModel: MenuItem[] = [
        {
            label: "Heute",
            icon: "pi pi-calendar",
            command: handleSetToday,
        },
        {
            label: "Aktueller Monat",
            icon: "pi pi-calendar",
            command: handleSetCurrentMonth,
        },
        {
            label: "Nächster Monat",
            icon: "pi pi-calendar",
            command: handleSetNextMonth,
        },
    ];

    const onAssigned = (assignment: Assignment) => {
        const accept = async () => {
            const cmd: Command<AssignBrotherCommandData> = {
                type: AssignmentCommands.AssignBrother,
                data: {
                    assignmentId: assignment.assignmentId,
                    brotherId: user.brotherId,
                    nominationId: null,
                    text: null,
                },
            };

            const result = await assignmentService.assignBrother(cmd);
            if (result.data?.success) {
                const changedData = await scheduleService.findAll();
                setSchedules(changedData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du diese Zuteilung übernehmen?`,
            header: "Übernahme bestätigen",
            icon: "pi pi-info-circle",

            defaultFocus: "accept",
            acceptClassName: "p-button-success",
            acceptIcon: "pi pi-check",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            accept,
            reject,
        });
    };
    return (
        <div className="flex flex-column">
            <div className="flex flex-row justify-content-center surface-ground py-2 mb-2 text-xl text-primary font-semibold">
                Zuteilungen
            </div>
            <div className="card flex justify-content-center">
                <p>Zeitraum</p>
                <Calendar
                    className="mx-2 w-13rem"
                    value={dates}
                    onChange={e => setDates(e.value)}
                    selectionMode="range"
                    locale="de"
                    readOnlyInput
                    hideOnRangeSelection
                />
                <SplitButton
                    className="p-button-text"
                    onClick={handleSetToday}
                    model={calendarModel}
                    icon="pi pi-calendar"
                ></SplitButton>
            </div>
            <ConfirmDialog />
            <Accordion activeIndex={0} className="my-2">
                {schedules?.map(schedule => (
                    <AccordionTab header={schedule.title} key={schedule.scheduleId}>
                        <SchedulePanel
                            schedule={schedule}
                            from={dates ? (dates[0] ? dates[0] : startOfDay(Date.now())) : startOfDay(Date.now())}
                            to={dates ? (dates[1] ? dates[1] : endOfDay(Date.now())) : endOfDay(Date.now())}
                            onAssigned={onAssigned}
                            onChanged={fetchData}
                        />
                    </AccordionTab>
                ))}
            </Accordion>
        </div>
    );
};

export { Homepage };
