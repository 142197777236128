import { NominationItem } from "@cos/core";
import { NominationItemsTable } from "./NominationItemsTable";
import { nominationItemService } from "../../../services/nominationItemService";
import { useState, useEffect } from "react";

const NominationItemsPage = () => {
    const [data, setData] = useState<NominationItem[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await nominationItemService.findAll();
            setData(data);
        };
        fetchData();
    }, []);

    const handleOnChanged = (nominationItem: NominationItem) => {
        const newData = data.map(y => (y.nominationItemId === nominationItem.nominationItemId ? nominationItem : y));
        setData(newData);
    };
    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row">
                <NominationItemsTable nominationItems={data} allowEdit={true} onChanged={handleOnChanged} />
            </div>
        </div>
    );
};

export { NominationItemsPage };
