import { Nomination } from "@cos/core";

import { useState, useEffect } from "react";
import { nominationService } from "../../services/nomination";
import { NominationsTable } from "./NominationsTable";

const NominationsPage = () => {
    const [data, setData] = useState<Nomination[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await nominationService.findAll();
            setData(data);
        };
        fetchData();
    }, []);

    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row">
                <NominationsTable nominations={data} allowRemove={false} />
            </div>
        </div>
    );
};

export { NominationsPage };
