import {
    Brother,
    Command,
    Congregation,
    NominateCommandData,
    NominationCommands,
    NominationItem,
    Schedule,
} from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import _ from "lodash";
import { nominationItemService } from "../../services/nominationItemService";
import { congregationService } from "../../services/congregation";
import { scheduleService } from "../../services/schedule";
import { nominationService } from "../../services/nomination";

const NominateBrotherDialog = props => {
    const { visible, onHide } = props;
    const [data, setData] = useState<{
        brother?: Brother;
        congregation?: Congregation;
        schedule?: Schedule;
        nominationItem?: NominationItem;
    }>();
    const [nominationItems, setNominationItems] = useState<NominationItem[]>();
    const [congregations, setCongregations] = useState<Congregation[]>();
    const [schedules, setSchedules] = useState<Schedule[]>();

    useEffect(() => {
        const fetchData = async () => {
            const nominationItems = await nominationItemService.findAll();
            setNominationItems(nominationItems);
            const congregations = await congregationService.findAll();
            setCongregations(congregations);
            const schedules = await scheduleService.findAll();
            setSchedules(schedules);
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        const cmd: Command<NominateCommandData> = {
            type: NominationCommands.Nominate,
            data: {
                nominationId: uuidV4(),
                brotherId: props.brotherId,
                nominationItemId: data?.nominationItem?.nominationItemId || "",
                congregationId: data?.congregation?.congregationId || null,
                scheduleId: data?.schedule?.scheduleId || null,
            },
        };
        await nominationService.nominate(cmd);
        handleHide();
    };

    const handleHide = () => {
        setData(undefined);
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Ernennung hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-30rem"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="nominationItem" className="block">
                                Ernennung
                            </label>
                            <Dropdown
                                id="nominationItem"
                                name="nominationItem"
                                className="w-full"
                                value={data?.nominationItem}
                                optionLabel="name"
                                options={_.orderBy<NominationItem>(nominationItems, "name")}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="field w-full">
                            <label htmlFor="schedule" className="block">
                                Zuteilung
                            </label>
                            <Dropdown
                                id="schedule"
                                name="schedule"
                                className="w-full"
                                value={data?.schedule}
                                optionLabel="title"
                                options={_.orderBy<Schedule>(schedules, "title")}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="field w-full">
                            <label htmlFor="congregation" className="block">
                                Versammlung
                            </label>
                            <Dropdown
                                id="congregation"
                                name="congregation"
                                className="w-full"
                                value={data?.congregation}
                                optionLabel="name"
                                options={_.orderBy<Congregation>(congregations, "name")}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { NominateBrotherDialog };
