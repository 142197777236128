import { Assignment, Nomination, Schedule } from "@cos/core";
import { AssignmentCard } from "../assignments/AssignmentCard";
import { useEffect, useState } from "react";
import { assignmentService } from "../../services/assignment";
import _ from "lodash";
import { nominationService } from "../../services/nomination";
import { isAfter, isBefore } from "date-fns";
import { Accordion, AccordionTab } from "primereact/accordion";

type SchedulePanelProps = {
    schedule: Schedule;
    from: Date;
    to: Date;
    onAssigned: (assignment: Assignment) => void;
    onChanged: () => void;
};
const SchedulePanel = (props: SchedulePanelProps) => {
    const [data, setData] = useState<Assignment[]>();
    const [nominations, setNominations] = useState<Nomination[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const assignments = await assignmentService.findAll(props.schedule.scheduleId);

            setData(
                _.orderBy(
                    assignments.filter(y => isAfter(y.end, props.from) && isBefore(y.start, props.to)),
                    ["start", "end", "part.pos"],
                ),
            );
            const nominations = await nominationService.findByScheduleId(props.schedule.scheduleId);
            setNominations(nominations);
        };
        fetchData();
    }, [props.from, props.schedule, props.to]);
    return (
        <div className="flex flex-column w-full">
            <h3>{props.schedule.description}</h3>

            <Accordion>
                {_.orderBy(props.schedule.parts, "position").map(part => (
                    <AccordionTab
                        header={`${part.position}. ${part.name} ${" "}
                        ${data
                            ?.filter(y => y.part.schedulePartId === part.schedulePartId)
                            ?.map(y => y.assignedTo?.filter(b => b.brother.brotherId !== null)?.length)
                            ?.reduce((a, c) => a + c, 0)}
                        /
                        ${data
                            ?.filter(y => y.part.schedulePartId === part.schedulePartId)
                            ?.map(y => y.max)
                            ?.reduce((a, c) => a + c, 0)}
                    
                    `}
                    >
                        <div className="flex flex-row flex-wrap">
                            {data
                                ?.filter(y => y.part.schedulePartId === part.schedulePartId)
                                .map(y => (
                                    <AssignmentCard
                                        key={y.assignmentId}
                                        assignment={y}
                                        nominations={nominations}
                                        schedule={props.schedule}
                                        onAssigned={assignment => {
                                            if (assignment) {
                                                props.onAssigned(assignment);
                                            }
                                        }}
                                        onChanged={props.onChanged}
                                    />
                                ))}
                        </div>
                    </AccordionTab>
                ))}
            </Accordion>
        </div>
    );
};

export { SchedulePanel };
