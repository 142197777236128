import {
    AddNewScheduleCommandData,
    ChangeScheduleCommandData,
    Command,
    RemoveScheduleCommandData,
    Schedule,
    ScheduleCommands,
} from "@cos/core";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { scheduleService } from "../../services/schedule";
import { Column, ColumnEditorOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { AddNewScheduleDialog } from "./AddNewSchedule";
import { useLocation, useNavigate } from "react-router-dom";
import { DatatableHeader } from "../common/DatatableHeader";

type propsType = {
    schedules: Schedule[];
    allowEdit: boolean;
    onChanged: (schedule: Schedule) => void;
};

const ScheduleTable = (props: propsType) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(props.schedules);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    useEffect(() => {
        setData(props.schedules);
    }, [props.schedules]);

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeScheduleCommandData> = {
            type: ScheduleCommands.ChangeSchedule,
            data: {
                scheduleId: newData.scheduleId,
                title: newData.title,
                description: newData.description,
            },
        };

        const response = await scheduleService.changeSchedule(cmd);

        if (response.data.success) {
            props.onChanged(newData as Schedule);
        }
    };

    const handleSubmit = async schedule => {
        const cmd: Command<AddNewScheduleCommandData> = {
            type: ScheduleCommands.AddNewSchedule,
            data: { ...schedule },
        };

        const result = await scheduleService.addNewSchedule(cmd);
        if (result.data?.success) {
            const newData = [...data, schedule];
            setData(newData);
        }
        setVisible(false);
    };

    const handleShowParts = row => {
        navigate(`${location.pathname}/parts/${row.scheduleId}`);
    };

    const handleHide = () => {
        setVisible(!visible);
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const handleDelete = row => {
        confirmRemove(row);
    };

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveScheduleCommandData> = {
                type: ScheduleCommands.RemoveSchedule,
                data: { scheduleId: row.scheduleId },
            };

            const result = await scheduleService.removeSchedule(cmd);
            if (result.data?.success) {
                const newData = data.filter(y => y.scheduleId != row.scheduleId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du den Plan "${row.title}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };
    return (
        <>
            <ConfirmDialog />
            <AddNewScheduleDialog visible={visible} onHide={handleHide} onSubmit={handleSubmit} />

            <DataTable
                value={data}
                size={"small"}
                globalFilter={globalFilterValue}
                rowsPerPageOptions={[10, 20, 50]}
                rows={20}
                className="w-full"
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                dataKey="scheduleId"
                sortField="title"
                sortOrder={-1}
                header={
                    <DatatableHeader
                        text="Pläne"
                        onAdd={handleHide}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                paginator
            >
                <Column
                    field="title"
                    className="w-4"
                    editor={options => textEditor(options)}
                    body={row => (
                        <Button
                            label={row.title}
                            className=" p-button-text"
                            onClick={() => navigate(`./assignments/${row.scheduleId}`)}
                        />
                    )}
                    header="Plan"
                    sortable
                ></Column>
                <Column
                    field="description"
                    className="w-full"
                    editor={options => textEditor(options)}
                    header="Beschreibung"
                ></Column>
                <Column
                    body={row => (
                        <Button
                            icon="pi pi-ellipsis-v text-2xl"
                            className="p-button-secondary p-button-text"
                            onClick={() => handleShowParts(row)}
                        />
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    rowEditor={props.allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>

                <Column
                    body={row => (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-text"
                            onClick={() => handleDelete(row)}
                        />
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

export { ScheduleTable };
