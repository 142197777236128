import { Suspense, useEffect } from "react";
import App from "../../App";
import { useNavigate } from "react-router-dom";
import { authService } from "../../services/auth";

const Layout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const isSignin = await authService.checkSignin();
            if (!isSignin) {
                navigate("/signin");
            }
        };
        checkAuth();
    }, [navigate]);
    return (
        <Suspense fallback={<div>Lade ...</div>}>
            <App />
        </Suspense>
    );
};

export { Layout };
