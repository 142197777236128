import { Button } from "primereact/button";

import { Menubar } from "primereact/menubar";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../../services/auth";
import { MenuItem } from "primereact/menuitem";
import { useEffect, useState } from "react";

// type User = {
//     firstname: string;
//     lastname: string;
//     roles: [{ scheduleId: string; role: string }];
// };

const AppMenu = () => {
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false);

    const [user, setUser] = useState({ firstname: "", lastname: "", roles: [] });

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const user = JSON.parse(localStorage.getItem("user") || "");
            setUser(user);
            setIsAdmin(
                user.roles.some(r => r.scheduleId === "00000000-0000-0000-0000-000000000000" && r.role === "ADMIN"),
            );
        }
    }, []);

    const handleNavigateHome = () => {
        navigate("/home");
    };

    const handleSignout = async () => {
        await authService.signout();
        navigate("/signin", { replace: true });
    };

    const start = <img alt="logo" src="./assets/img/ios/40.png" onClick={handleNavigateHome} />;
    const end = (
        <div className="flex flex-row align-item-center">
            <span className="text text-sm text-secondary font-semibold font-italic align-self-center">
                <Link to={"/my"}>
                    {user.firstname} {user.lastname}
                </Link>
            </span>
            <Button label="Abmelden" icon="pi pi-sign-out" className="p-button p-button-link" onClick={handleSignout} />
        </div>
    );

    const model: MenuItem[] = [
        {
            label: "Übersicht",
            icon: "pi pi-home",
            command: () => navigate("/home"),
        },
        {
            label: "Meine Einsätze",
            icon: "pi pi-user",
            command: () => navigate("/my"),
        },

        {
            label: "Einstellungen",
            icon: "pi pi-cog",
            command: () => navigate("/option"),
            disabled: !isAdmin,
        },
        {
            label: "Impressum & Datenschutz",
            icon: "pi pi-info-circle",
            command: () => navigate("/impressum"),
            disabled: !isAdmin,
        },
    ];

    return <Menubar model={model} start={start} end={end} />;
};

export { AppMenu };
