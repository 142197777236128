import {
    AddNewBrotherCommandData,
    AddRoleBrotherCommandData,
    Brother,
    ChangeBrotherCommandData,
    Command,
    CommandResult,
    RemoveBrotherCommandData,
    RemoveRoleBrotherCommandData,
    Role,
    SetResetTokenCommandData,
    SetStartcodeCommandData,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/brothers`;

const brotherService = {
    findAll: async (): Promise<Brother[]> => {
        const { data } = await api.get(`${API_URL}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (brotherId: string): Promise<Brother | null> => {
        const { data } = await api.get(`${API_URL}/${brotherId}`);
        if (data?.success) {
            return data.data;
        }
        return null;
    },
    findByCongregationId: async (congregationId: string): Promise<Brother[]> => {
        const { data } = await api.get(`${API_URL}/byCongregation/${congregationId}`);
        if (data?.success) {
            return data.data || [];
        }
        return [];
    },
    findByStartcode: async (startcode: string): Promise<Brother[]> => {
        const { data } = await api.get(`${API_URL}/byStartcode/${startcode}`);
        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findByEmail: async (email: string): Promise<Brother | null> => {
        const { data } = await api.get(`${API_URL}/byEmail/${email}`);
        if (data.success) {
            return data.data;
        }
        return null;
    },
    getStartcode: async (brotherId: string): Promise<string | null> => {
        const { data } = await api.get(`${API_URL}/startcode/${brotherId}`);
        if (data.success) {
            return data.data.startcode;
        }
        return null;
    },
    setStartcode: async (cmd: Command<SetStartcodeCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },
    getResettoken: async (brotherId: string): Promise<string | null> => {
        const { data } = await api.get(`${API_URL}/resettoken/${brotherId}`);
        if (data.success) {
            return data.data.resettoken;
        }
        return null;
    },
    getRoles: async (brotherId: string): Promise<Role[]> => {
        const { data } = await api.get(`${API_URL}/roles/${brotherId}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    setResettoken: async (cmd: Command<SetResetTokenCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },

    addNewBrother: async (cmd: Command<AddNewBrotherCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeBrother: async (cmd: Command<ChangeBrotherCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeBrother: async (cmd: Command<RemoveBrotherCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },

    addRole: async (cmd: Command<AddRoleBrotherCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeRole: async (cmd: Command<RemoveRoleBrotherCommandData>): Promise<CommandResult> => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { brotherService };
