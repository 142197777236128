import {
    AddNewSchedulePartCommandData,
    ChangeSchedulePartCommandData,
    Command,
    QueryResult,
    RemoveSchedulePartCommandData,
    Schedule,
    SchedulePart,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/schedules/parts`;

const schedulePartService = {
    findAll: async (scheduleId: string): Promise<SchedulePart[]> => {
        const { data } = await api.get(`${API_URL}/${scheduleId}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (scheduleId: string, schedulePartId: string): Promise<Schedule | null> => {
        const result: QueryResult<Schedule> = await api.get(`${API_URL}/${scheduleId}/${schedulePartId}`);
        if (result.success) {
            return result.data;
        }
        return null;
    },
    addNewSchedulePart: async (cmd: Command<AddNewSchedulePartCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeSchedulePart: async (cmd: Command<ChangeSchedulePartCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeSchedulePart: async (cmd: Command<RemoveSchedulePartCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { schedulePartService };
