import {
    AddNewScheduleCommandData,
    ChangeScheduleCommandData,
    Command,
    QueryResult,
    RemoveScheduleCommandData,
    Schedule,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/schedules`;

const scheduleService = {
    findAll: async (): Promise<Schedule[]> => {
        const { data } = await api.get(`${API_URL}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (scheduleId: string): Promise<Schedule | null> => {
        const result: QueryResult<Schedule> = (await api.get(`${API_URL}/${scheduleId}`))?.data;
        if (result.success) {
            return result.data;
        }
        return null;
    },
    addNewSchedule: async (cmd: Command<AddNewScheduleCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeSchedule: async (cmd: Command<ChangeScheduleCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeSchedule: async (cmd: Command<RemoveScheduleCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { scheduleService };
