import { useEffect, useState } from "react";
import { Schedule } from "@cos/core";
import { scheduleService } from "../../services/schedule";
import { ScheduleTable } from "./ScheduleTable";
import { Route, Routes } from "react-router-dom";
import { SchedulePartsPage } from "./parts/SchedulePartsPage";
import { AssignmentsPage } from "../assignments/AssignmentsPage";

const SchedulesPage = () => {
    const [data, setData] = useState<Schedule[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await scheduleService.findAll();
            setData(data);
        };
        fetchData();
    }, []);

    const handleOnChanged = (schedule: Schedule) => {
        const newData = data.map(y => (y.scheduleId === schedule.scheduleId ? schedule : y));
        setData(newData);
    };

    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row">
                <Routes>
                    <Route
                        index
                        element={<ScheduleTable schedules={data} allowEdit={true} onChanged={handleOnChanged} />}
                    />
                    <Route
                        path="parts/:scheduleId"
                        element={
                            <div className="w-full">
                                <SchedulePartsPage />
                            </div>
                        }
                    />
                    <Route
                        path="assignments/:scheduleId/*"
                        element={
                            <div className="w-full">
                                <AssignmentsPage />
                            </div>
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

export { SchedulesPage };
