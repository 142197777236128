import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { Command, DismissCommandData, Nomination, NominationCommands } from "@cos/core";
import { Button } from "primereact/button";
import { nominationService } from "../../services/nomination";
import { useEffect, useState } from "react";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DatatableHeader } from "../common/DatatableHeader";

type propsType = {
    nominations: Nomination[];
    allowRemove: boolean;
};

const NominationsTable = (props: propsType) => {
    const [data, setData] = useState<Nomination[]>();
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    useEffect(() => {
        setData(props.nominations);
    }, [props.nominations]);

    const brotherBody = option => <div>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</div>;
    const nominationItemBody = option => (
        <div>{`${option.nominationItem?.name} (${option.nominationItem?.shortName})`}</div>
    );
    const congregationBody = option => <div>{`${option.congregation?.name ? option?.congregation?.name : ""}`}</div>;
    const scheduleBody = option => <div>{`${option.schedule?.title ? option?.schedule?.title : ""}`}</div>;
    const handleOnDelete = row => {
        confirmRemove(row);
    };
    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<DismissCommandData> = {
                type: NominationCommands.Dismiss,
                data: { nominationId: row.nominationId },
            };

            const result = await nominationService.dismiss(cmd);
            if (result.data?.success) {
                const newData = data?.filter(y => y.nominationId != row.nominationId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du die Ernennung "${row.nominationItem.name}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };

    return (
        <>
            <ConfirmDialog />
            <DataTable
                globalFilter={globalFilterValue}
                value={data}
                size={"small"}
                rowsPerPageOptions={[10, 20, 50]}
                rows={20}
                className="w-full"
                dataKey="nominationId"
                paginator
                header={
                    <DatatableHeader
                        text="Ernannte Brüder"
                        readonly={true}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                sortMode="multiple"
            >
                <Column
                    field="brother"
                    header="Bruder"
                    body={brotherBody}
                    sortable
                    sortField="brother.lastname"
                ></Column>
                <Column
                    field="nominationItem"
                    header="Ernennung"
                    body={nominationItemBody}
                    sortField="nominationItem.name"
                    sortable
                ></Column>
                <Column
                    field="congregation"
                    header="Versammlung"
                    body={congregationBody}
                    sortField="congregation.name"
                    sortable
                ></Column>
                <Column
                    field="schedule"
                    header="Zuteilung"
                    body={scheduleBody}
                    sortField="schedule.title"
                    sortable
                ></Column>
                {props.allowRemove && (
                    <Column
                        body={row => (
                            <Button
                                icon="pi pi-trash"
                                className="p-button-danger p-button-link"
                                onClick={() => handleOnDelete(row)}
                            />
                        )}
                        headerClassName="w-4rem"
                        bodyStyle={{ textAlign: "center" }}
                    ></Column>
                )}
            </DataTable>
        </>
    );
};

export { NominationsTable };
