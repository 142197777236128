import { Brother } from "@cos/core";
import { useEffect, useState } from "react";
import { brotherService } from "../../services/brother";
import { BrothersTable } from "./BrothersTable";
import { Outlet, Route, Routes } from "react-router-dom";
import { BrotherProfile } from "./BrotherProfile";
import { BrotherAssignmentsPage } from "./BrotherAssignmentsPage";

const BrothersPage = () => {
    const [data, setData] = useState<Brother[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const brothers = await brotherService.findAll();
            setData(brothers);
        };
        fetchData();
    }, []);

    const handleOnChanged = (brother: Brother) => {
        const newData = data.map(y => (y.brotherId === brother.brotherId ? brother : y));
        setData(newData);
    };

    return (
        <>
            <Routes>
                <Route
                    index
                    element={
                        <div className="flex flex-column m-2">
                            <div className="flex flex-row h-40rem">
                                <BrothersTable brothers={data} allowEdit={true} onChanged={handleOnChanged} />
                            </div>
                        </div>
                    }
                ></Route>
                <Route path="profile/:brotherId" element={<BrotherProfile />}></Route>
                <Route path="assignments/:brotherId" element={<BrotherAssignmentsPage />}></Route>
            </Routes>
            <Outlet />
        </>
    );
};

export { BrothersPage };
