import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { useEffect, useState } from "react";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
    AddNewBrotherCommandData,
    ChangeBrotherCommandData,
    Command,
    Brother,
    BrotherCommands,
    RemoveBrotherCommandData,
    Congregation,
} from "@cos/core";
import { brotherService } from "../../services/brother";
import { AddNewBrotherDialog } from "./AddNewBrother";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { congregationService } from "../../services/congregation";
import { useNavigate } from "react-router-dom";
import { DatatableHeader } from "../common/DatatableHeader";

type propsType = {
    brothers: Brother[];
    allowEdit: boolean;
    onChanged: (brother: Brother) => void;
};

const BrothersTable = (props: propsType) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<Brother[]>([]);
    const [congregations, setCongregations] = useState<Congregation[]>();
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    useEffect(() => {
        const fetchData = async () => {
            const congregations = await congregationService.findAll();
            setCongregations(congregations);
        };
        fetchData();
    }, []);

    useEffect(() => {
        setData(props.brothers);
    }, [props.brothers]);

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeBrotherCommandData> = {
            type: BrotherCommands.ChangeBrother,
            data: {
                brotherId: newData.brotherId,
                firstname: newData.firstname,
                lastname: newData.lastname,
                congregationId: newData.congregation?.congregationId,
                email: newData.email,
            },
        };

        const response = await brotherService.changeBrother(cmd);

        if (response.data?.success) {
            props.onChanged(newData as Brother);
        }
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const congregationEditor = (options: ColumnEditorOptions) => {
        return (
            <Dropdown
                optionLabel="name"
                value={options.value}
                onChange={(e: DropdownChangeEvent) => options.editorCallback!(e.target.value)}
                options={congregations}
                placeholder="Versammlung auswählen"
                className="w-full md:w-14rem"
            />
        );
    };

    const handleSubmit = async (brother: Brother) => {
        const { brotherId, firstname, lastname, email } = brother;

        const cmd: Command<AddNewBrotherCommandData> = {
            type: BrotherCommands.AddNewBrother,
            data: { brotherId, firstname, lastname, email, congregationId: brother.congregation?.congregationId || "" },
        };

        const result = await brotherService.addNewBrother(cmd);
        if (result.data?.success) {
            const newData = [...data, brother];
            setData(newData);
        }
        setVisible(false);
    };

    const handleHide = () => {
        setVisible(!visible);
    };

    const handleDelete = row => {
        confirmRemove(row);
    };

    const handleShowDetails = row => {
        navigate(`./profile/${row.brotherId}`);
    };

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveBrotherCommandData> = {
                type: BrotherCommands.RemoveBrother,
                data: { brotherId: row.brotherId },
            };

            const result = await brotherService.removeBrother(cmd);
            if (result.data?.success) {
                const newData = data.filter(y => y.brotherId != row.brotherId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du  "${row.lastname}, ${row.firstname}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };
    return (
        <>
            <ConfirmDialog />
            <AddNewBrotherDialog
                visible={visible}
                congregations={congregations}
                onHide={handleHide}
                onSubmit={handleSubmit}
            />

            <DataTable
                globalFilterFields={["firstname", "lastname", "congregation.name"]}
                value={data}
                globalFilter={globalFilterValue}
                filterDisplay="row"
                size={"small"}
                rows={20}
                rowsPerPageOptions={[10, 20, 50]}
                className="w-full h-max"
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                dataKey="brotherId"
                multiSortMeta={[
                    { field: "lastname", order: 1 },
                    { field: "firstname", order: 1 },
                ]}
                sortMode="multiple"
                header={
                    <DatatableHeader
                        text="Brüder"
                        onAdd={handleHide}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                paginator
                scrollable
            >
                <Column
                    field="lastname"
                    className="w-auto"
                    editor={options => textEditor(options)}
                    body={row => (
                        <Button className="p-button-text" label={row.lastname} onClick={() => handleShowDetails(row)} />
                    )}
                    header="Name"
                    sortable
                    frozen={true}
                    alignFrozen="left"
                ></Column>
                <Column
                    field="firstname"
                    className="w-auto"
                    editor={options => textEditor(options)}
                    header="Vorname"
                    sortable
                ></Column>
                <Column
                    field="email"
                    className="w-auto"
                    editor={options => textEditor(options)}
                    header="Email"
                    sortable
                ></Column>
                <Column
                    field="congregation"
                    className="w-auto"
                    body={options => <div>{options.congregation?.name}</div>}
                    editor={options => congregationEditor(options)}
                    header="Versammlung"
                    sortable
                    sortField="congregation.name"
                ></Column>
                <Column
                    rowEditor={props.allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    body={row => (
                        <div className="flex">
                            <Button
                                icon="pi pi-clock"
                                className="p-button-text "
                                onClick={() => navigate(`./assignments/${row.brotherId}`)}
                            />
                            <Button
                                icon="pi pi-trash"
                                className="p-button-danger p-button-text"
                                onClick={() => handleDelete(row)}
                            />
                        </div>
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

export { BrothersTable };
