import { Schedule } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";

const AddNewScheduleDialog = props => {
    const { visible, onHide } = props;
    const [data, setData] = useState<Partial<Schedule>>({
        scheduleId: uuidV4(),
        title: "",
        description: "",
    });

    const handleSubmit = () => {
        const schedule = { ...data };
        setData({
            scheduleId: uuidV4(),
            title: "",
            description: "",
        });
        props.onSubmit(schedule);
    };

    const handleHide = () => {
        setData({
            scheduleId: uuidV4(),
            title: "",
            description: "",
        });
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Plan hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-5"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="title" className="block">
                                Titel
                            </label>
                            <InputText
                                className="w-full"
                                id="title"
                                name="title"
                                value={data.title}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="description" className="block">
                                Beschreibung
                            </label>
                            <InputText
                                className="w-full"
                                id="description"
                                name="description"
                                value={data.description}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewScheduleDialog };
