import { AssignBrotherCommandData, Assignment, AssignmentCommands, Command, Nomination } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import { useEffect, useState } from "react";

import { assignmentService } from "../../services/assignment";
import { InputText } from "primereact/inputtext";
import { nominationService } from "../../services/nomination";
import _ from "lodash";
import { differenceInMinutes, endOfDay, isAfter, isBefore, startOfDay } from "date-fns";

type AssignBrotherProps = {
    scheduleId: string;
    assignment?: Assignment | null;
    visible: boolean;
    onHide: () => void;
};

const AssignBrotherDialog = (props: AssignBrotherProps) => {
    const { visible, onHide } = props;
    const [data, setData] = useState<{
        text?: string;
        nominationId?: string;
    }>();

    const [options, setOptions] = useState<{ name: string; nominationId: string }[]>();
    const [nominations, setNominations] = useState<Nomination[]>();

    useEffect(() => {
        const fetchData = async () => {
            const assignments = await assignmentService.findAll(props.scheduleId);

            const nominations = (await nominationService.findAll()).filter(
                y => y.schedule?.scheduleId === props.scheduleId,
            );
            const options = nominations
                .filter(y => !props.assignment?.assignedTo.some(a => a.brother.brotherId === y.brother.brotherId))
                .map(y => {
                    const assignedMinutes = assignments
                        .filter(assignment => {
                            const day = startOfDay(props.assignment?.start as string);
                            return (
                                isAfter(assignment.start, day) &&
                                isBefore(assignment.start, endOfDay(day)) &&
                                assignment.assignedTo.some(a => a.brother.brotherId === y.brother.brotherId)
                            );
                        })
                        .map(x => differenceInMinutes(x.end, x.start))
                        .reduce((a, c) => a + c, 0);

                    return {
                        name: `${y.brother.lastname}, ${y.brother.firstname} (${y.nominationItem?.shortName}) `,
                        nominationId: y.nominationId,
                        assignedMinutes,
                    };
                });
            setNominations(nominations);
            setOptions(options);
        };
        fetchData();
    }, [props.assignment?.assignedTo, props.scheduleId]);

    const handleSubmit = async () => {
        const nomination = nominations?.find(y => y.nominationId === data?.nominationId);

        const cmd: Command<AssignBrotherCommandData> = {
            type: AssignmentCommands.AssignBrother,
            data: {
                assignmentId: props.assignment?.assignmentId || "",
                brotherId: nomination?.brother?.brotherId || "",
                nominationId: data?.nominationId || "",
                text: data?.text || "",
            },
        };

        const result = await assignmentService.assignBrother(cmd);
        if (result.data.success) {
            handleHide();
        }
    };

    const handleHide = () => {
        setData(undefined);
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };

    return (
        <Dialog
            header="Bruder zuteilen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-30rem"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field col-12">
                            <label htmlFor="schedulePart" className="block">
                                Zuteilung
                            </label>
                            <Dropdown
                                id="nominationId"
                                name="nominationId"
                                className="w-full"
                                options={_.orderBy(options, ["assignedMinutes", "name"])}
                                optionLabel="name"
                                itemTemplate={option => (
                                    <div className="flex flex-column">
                                        <div className="my-1">{option.name}</div>
                                        <div className="text-xs">Tageseinsatzzeit: {option.assignedMinutes} min</div>
                                    </div>
                                )}
                                optionValue="nominationId"
                                value={data?.nominationId}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="field col-12">
                            <label htmlFor="text" className="block">
                                Bemerkung
                            </label>
                            <InputText
                                value={data?.text}
                                className="w-full"
                                onChange={handleChange}
                                id="text"
                                name="text"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex col-12 flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AssignBrotherDialog };
