import { Column, ColumnEditorOptions } from "primereact/column";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { congregationService } from "../../services/congregation";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { AddNewCongregationDialog } from "./AddNewCongregation";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
    AddNewCongregationCommandData,
    ChangeCongregationCommandData,
    Command,
    Congregation,
    CongregationCommands,
    RemoveCongregationCommandData,
} from "@cos/core";
import { DatatableHeader } from "../common/DatatableHeader";

type propsType = {
    congregations: Congregation[];
    allowEdit: boolean;
    onChanged: (congregation: Congregation) => void;
};

const CongregationTable = (props: propsType) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(props.congregations);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    useEffect(() => {
        setData(props.congregations);
    }, [props.congregations]);

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeCongregationCommandData> = {
            type: CongregationCommands.ChangeCongregation,
            data: {
                congregationId: newData.congregationId,
                name: newData.name,
            },
        };

        const response = await congregationService.changeCongregation(cmd);

        if (response.data.success) {
            props.onChanged(newData as Congregation);
        }
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const handleSubmit = async congregation => {
        const cmd: Command<AddNewCongregationCommandData> = {
            type: CongregationCommands.AddNewCongregation,
            data: { ...congregation },
        };

        const result = await congregationService.addNewCongregation(cmd);
        if (result.data?.success) {
            const newData = [...data, congregation];
            setData(newData);
        }
        setVisible(false);
    };

    const handleOnHide = () => {
        setVisible(!visible);
    };

    const handleOnDelete = row => {
        confirmRemove(row);
    };

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveCongregationCommandData> = {
                type: CongregationCommands.RemoveCongregation,
                data: { congregationId: row.congregationId },
            };

            const result = await congregationService.removeCongregation(cmd);
            if (result.data?.success) {
                const newData = data.filter(y => y.congregationId != row.congregationId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du die Versammlung "${row.name}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };
    return (
        <>
            <ConfirmDialog />
            <AddNewCongregationDialog visible={visible} onHide={handleOnHide} onSubmit={handleSubmit} />

            <DataTable
                value={data}
                globalFilter={globalFilterValue}
                size={"small"}
                rowsPerPageOptions={[10, 20, 50]}
                rows={20}
                className="w-full"
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                dataKey="congregationId"
                sortField="name"
                sortOrder={-1}
                header={
                    <DatatableHeader
                        text="Versammlungen"
                        onAdd={() => setVisible(true)}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                paginator
            >
                <Column
                    field="name"
                    className="w-full"
                    editor={options => textEditor(options)}
                    header="Versammlung"
                    sortable
                ></Column>
                <Column
                    rowEditor={props.allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    body={row => (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-link"
                            onClick={() => handleOnDelete(row)}
                        />
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

export { CongregationTable };
