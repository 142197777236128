import { Assignment, AssignmentCommands, Command, ExchangeBrotherCommandData, Nomination } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { assignmentService } from "../../services/assignment";
import _ from "lodash";

export const ExchangeDialog = (props: {
    assignment: Assignment;
    brotherId: string;
    nominations?: Nomination[];
    visible: boolean;
    onHide: () => void;
}) => {
    const [data, setData] = useState<string>(props.brotherId);
    const handleExchange = async () => {
        const cmd: Command<ExchangeBrotherCommandData> = {
            type: AssignmentCommands.ExchangeBrother,
            data: {
                assignmentId: props.assignment.assignmentId,
                brotherId: props.brotherId,
                newBrotherId: data,
                nominationId: null,
                text: "Geändert",
            },
        };
        await assignmentService.exchangeBrother(cmd);
        setData("");
        props.onHide();
    };
    return (
        <Dialog
            className="md:w-6 sm:w-10"
            visible={props.visible}
            header="Zuteilung übergeben an"
            onHide={props.onHide}
        >
            <Dropdown
                className="w-full"
                value={data}
                onChange={e => setData(e.value)}
                optionValue="brother.brotherId"
                itemTemplate={option => <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>}
                valueTemplate={option => <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>}
                optionLabel="brother.lastname"
                options={_.orderBy(
                    props.nominations?.filter(y => y.brother?.brotherId !== props.brotherId),
                    ["brother.lastname", "brother.firstname"],
                )}
            />
            <div className="flex justify-content-end mt-3">
                <Button className="p-button-text mr-2" label="Abbrechen" onClick={props.onHide} />
                <Button className="p-button-success" label="Ok" icon="pi pi-check" onClick={handleExchange} />
            </div>
        </Dialog>
    );
};
