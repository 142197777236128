import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { scheduleService } from "../../services/schedule";
import { brotherService } from "../../services/brother";
import { AddRoleBrotherCommandData, BrotherCommands, Command } from "@cos/core";

type AddNewBrotherDialogProps = {
    brotherId: string;
    visible: boolean;
    onHide: () => void;
};

const AddNewRoleDialog = (props: AddNewBrotherDialogProps) => {
    const { visible, onHide } = props;

    const [scheduleOptions, setScheduleOptions] = useState<{ scheduleId: string; name: string }[]>();

    useEffect(() => {
        const fetchData = async () => {
            const schedules = await scheduleService.findAll();
            const appOption = {
                scheduleId: "00000000-0000-0000-0000-000000000000",
                name: "Anwendung",
            };
            const scheduleOptions = schedules.map(y => ({ scheduleId: y.scheduleId, name: y.title }));

            setScheduleOptions([...scheduleOptions, appOption]);
        };
        fetchData();
    }, []);

    const [data, setData] = useState({
        role: "MEMBER",
        scheduleId: "00000000-0000-0000-0000-000000000000",
    });

    const options = ["ADMIN", "MEMBER"];

    const handleSubmit = async () => {
        const cmd: Command<AddRoleBrotherCommandData> = {
            type: BrotherCommands.AddRole,
            data: {
                roleId: uuidV4(),
                brotherId: props.brotherId,
                scheduleId: data.scheduleId,
                role: data.role,
            },
        };
        await brotherService.addRole(cmd);
        onHide();
    };

    const handleHide = () => {
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Rolle hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-30rem"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="congregation" className="block">
                                Rolle
                            </label>
                            <Dropdown
                                id="role"
                                name="role"
                                className="w-full"
                                value={data.role}
                                options={options}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="field w-full">
                            <label htmlFor="congregation" className="block">
                                Rolle für
                            </label>
                            <Dropdown
                                id="scheduleId"
                                name="scheduleId"
                                className="w-full"
                                value={data.scheduleId}
                                optionLabel="name"
                                optionValue="scheduleId"
                                options={scheduleOptions}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewRoleDialog };
