import { Brother, BrotherCommands, Command, RemoveRoleBrotherCommandData, Role } from "@cos/core";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { brotherService } from "../../services/brother";
import { Button } from "primereact/button";
import { useState } from "react";
import { AddNewRoleDialog } from "./AddNewRole";
import { DatatableHeader } from "../common/DatatableHeader";

type RolesTableProps = {
    brother?: Brother | null;
    roles: Role[];
    onChanged: () => void;
};

const RolesTable = (props: RolesTableProps) => {
    const [visible, setVisible] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveRoleBrotherCommandData> = {
                type: BrotherCommands.RemoveRole,
                data: { roleId: row.roleId },
            };

            const result = await brotherService.removeRole(cmd);
            if (result.data?.success) {
                props.onChanged();
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du  "${row.lastname}, ${row.firstname}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };

    return (
        <>
            <ConfirmDialog />
            <AddNewRoleDialog
                visible={visible}
                brotherId={props.brother?.brotherId || ""}
                onHide={() => {
                    setVisible(false);
                    props.onChanged();
                }}
            />
            <DataTable
                className="w-full h-max"
                globalFilter={globalFilterValue}
                header={
                    <DatatableHeader
                        text="Rollen"
                        onAdd={() => setVisible(true)}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                value={props.roles}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                size="small"
            >
                <Column field="role"></Column>
                <Column field="schedule.title"></Column>
                <Column
                    body={row => (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-link"
                            onClick={() => confirmRemove(row)}
                        />
                    )}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

export { RolesTable };
