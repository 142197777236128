import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Password } from "primereact/password";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../services/auth";
import { InputText } from "primereact/inputtext";

type formsData = {
    resetToken?: string;

    email?: string;
    password?: string;
    password2?: string;
};

const ResetPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [data, setData] = useState<formsData>();

    // useEffect(() => {
    //     if (params.resetToken) {
    //         const newData = { ...data };
    //         newData.resetToken = params.resetToken;
    //         setData(newData);
    //     }
    // }, []);

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };

    const handleReset = async () => {
        const resetToken = params.resetToken || "";
        const password = data?.password || "";
        const email = data?.email || "";
        if (data?.password?.trim() === data?.password2?.trim()) {
            const success = await authService.resetPassword(email, resetToken, password);
            if (success) {
                navigate("/signin");
            }
        }
    };

    return (
        <div className="flex flex-column h-screen justify-content-center align-items-center">
            <Card title="CO Servant" subTitle="Kennwort zurücksetzten" className="lg:w-3 md:w-6 sm:w-11">
                <div className="grid m-0 ">
                    <div className="field w-full ">
                        <label htmlFor="email">Email</label>
                        <InputText name="email" className="w-full " value={data?.email} onChange={handleChange} />
                    </div>
                    <div className="field w-full  ">
                        <label htmlFor="password">Kennwort</label>
                        <Password
                            name="password"
                            className="w-12"
                            value={data?.password}
                            onChange={handleChange}
                            toggleMask
                            feedback={false}
                        />
                    </div>
                    <div className="field w-full  ">
                        <label htmlFor="password2">Kennwort Wiederholung</label>
                        <Password
                            name="password2"
                            className="w-12"
                            value={data?.password2}
                            onChange={handleChange}
                            toggleMask
                            feedback={false}
                        />
                    </div>
                </div>
                <div className="flex flex-row">
                    <Button label="Zurücksetzen" onClick={handleReset} />
                </div>
            </Card>
        </div>
    );
};

export { ResetPage };
