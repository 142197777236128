import {
    AddNewNominationItemCommandData,
    ChangeNominationItemCommandData,
    Command,
    NominationItem,
    QueryResult,
    RemoveNominationItemCommandData,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/nominations/items`;

const nominationItemService = {
    findAll: async (): Promise<NominationItem[]> => {
        const { data } = await api.get(`${API_URL}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (nominationItemId: string): Promise<NominationItem | null> => {
        const result: QueryResult<NominationItem> = await api.get(`${API_URL}/${nominationItemId}`);
        if (result.success) {
            return result.data;
        }
        return null;
    },

    addNewNominationItem: async (cmd: Command<AddNewNominationItemCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeNominationItem: async (cmd: Command<ChangeNominationItemCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeNominationItem: async (cmd: Command<RemoveNominationItemCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { nominationItemService };
