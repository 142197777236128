import { Assignment } from "@cos/core";
import { useEffect, useState } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import { assignmentService } from "../../services/assignment";
import { AssignmentsTable } from "./AssignmentsTable";
import { AssignedToPage } from "./AssignedToPage";

const AssignmentsPage = () => {
    const params = useParams();

    const [data, setData] = useState<Assignment[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await assignmentService.findAll(params.scheduleId || "");
            setData(data);
        };
        fetchData();
    }, [params.scheduleId]);

    return (
        <>
            <Routes>
                <Route
                    index
                    element={
                        <div className="flex flex-column m-2">
                            <div className="flex flex-row">
                                <AssignmentsTable assignments={data} scheduleId={params.scheduleId || ""} allowEdit />
                            </div>
                        </div>
                    }
                />
                <Route path="assignedTo/:assignmentId" element={<AssignedToPage />} />
            </Routes>
            <Outlet />
        </>
    );
};

export { AssignmentsPage };
