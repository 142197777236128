import { Command, DismissCommandData, NominateCommandData, Nomination, QueryResult } from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/nominations`;

const nominationService = {
    findAll: async (): Promise<Nomination[]> => {
        const { data } = await api.get(`${API_URL}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (nominationId: string): Promise<Nomination | null> => {
        const result: QueryResult<Nomination> = await api.get(`${API_URL}/${nominationId}`);
        if (result.success) {
            return result.data;
        }
        return null;
    },
    findByBrother: async (brotherId: string): Promise<Nomination[]> => {
        const { data } = await api.get(`${API_URL}/byBrother/${brotherId}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findByScheduleId: async (scheduleId: string): Promise<Nomination[]> => {
        const { data } = await api.get(`${API_URL}/bySchedule/${scheduleId}`);
        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    nominate: async (cmd: Command<NominateCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    dismiss: async (cmd: Command<DismissCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { nominationService };
