import {
    AddNewSchedulePartCommandData,
    ChangeSchedulePartCommandData,
    Command,
    RemoveSchedulePartCommandData,
    SchedulePart,
    SchedulePartCommands,
} from "@cos/core";
import { DataTable, DataTableRowEditCompleteEvent } from "primereact/datatable";
import { useEffect, useState } from "react";

import { Column, ColumnEditorOptions } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

import { schedulePartService } from "../../../services/schedulePart";
import { AddNewSchedulePartDialog } from "./AddNewSchedulePart";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { useParams } from "react-router-dom";
import { DatatableHeader } from "../../common/DatatableHeader";

type propsType = {
    scheduleParts: SchedulePart[];
    allowEdit: boolean;
    onChanged: (schedulePart: SchedulePart) => void;
};

const SchedulePartTable = (props: propsType) => {
    const params = useParams();
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(props.scheduleParts);
    const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

    useEffect(() => {
        setData(props.scheduleParts);
    }, [props.scheduleParts]);

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeSchedulePartCommandData> = {
            type: SchedulePartCommands.ChangeSchedulePart,
            data: {
                schedulePartId: newData.schedulePartId,
                scheduleId: newData.scheduleId,
                name: newData.name,
                position: newData.position,
            },
        };

        const response = await schedulePartService.changeSchedulePart(cmd);

        if (response.data.success) {
            props.onChanged(newData as SchedulePart);
        }
    };

    const handleSubmit = async schedulePart => {
        const cmd: Command<AddNewSchedulePartCommandData> = {
            type: SchedulePartCommands.AddNewSchedulePart,
            data: { ...schedulePart },
        };

        const result = await schedulePartService.addNewSchedulePart(cmd);
        if (result.data?.success) {
            const newData = [...data, schedulePart];
            setData(newData);
        }
        setVisible(false);
    };
    const handleHide = () => {
        setVisible(!visible);
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return (
            <InputText
                type="text"
                value={options.value}
                className="w-full"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)}
            />
        );
    };

    const numberEditor = (options: ColumnEditorOptions) => {
        return (
            <InputNumber
                min={1}
                maxFractionDigits={0}
                step={1}
                showButtons={true}
                value={options.value}
                className="w-full"
                onChange={(e: InputNumberChangeEvent) => options.editorCallback!(e.value)}
            />
        );
    };

    const handleDelete = row => {
        confirmRemove(row);
    };

    const confirmRemove = (row: SchedulePart) => {
        const accept = async () => {
            const cmd: Command<RemoveSchedulePartCommandData> = {
                type: SchedulePartCommands.RemoveSchedulePart,
                data: { schedulePartId: row.schedulePartId },
            };

            const result = await schedulePartService.removeSchedulePart(cmd);
            if (result.data?.success) {
                const newData = data.filter(y => y.schedulePartId != row.schedulePartId);
                setData(newData);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du den Teilplan "${row.name}" löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };
    return (
        <>
            <ConfirmDialog />
            <AddNewSchedulePartDialog
                visible={visible}
                onHide={handleHide}
                onSubmit={handleSubmit}
                scheduleId={params.scheduleId}
            />
            <DataTable
                value={data}
                size={"small"}
                rowsPerPageOptions={[10, 20, 50]}
                rows={20}
                className="w-full"
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                dataKey="scheduleId"
                sortField="title"
                sortOrder={-1}
                header={
                    <DatatableHeader
                        text="Teilpläne"
                        onAdd={handleHide}
                        onFilter={e => setGlobalFilterValue(e)}
                        filter={globalFilterValue}
                    />
                }
                paginator
            >
                <Column
                    field="name"
                    className="w-full"
                    editor={options => textEditor(options)}
                    header="Teilplan"
                    sortable
                ></Column>

                <Column
                    field="position"
                    className="w-full"
                    editor={options => numberEditor(options)}
                    header="Position"
                    sortable
                ></Column>
                <Column
                    rowEditor={props.allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    body={row => (
                        <Button
                            icon="pi pi-trash"
                            className="p-button-danger p-button-link"
                            onClick={() => handleDelete(row)}
                        />
                    )}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

export { SchedulePartTable };
