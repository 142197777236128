import {
    Brother,
    BrotherCommands,
    Command,
    Nomination,
    Role,
    SetResetTokenCommandData,
    SetStartcodeCommandData,
} from "@cos/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { brotherService } from "../../services/brother";
import appConfig from "../../app.config";

import { customAlphabet } from "nanoid";
import { nolookalikes } from "nanoid-dictionary";
import { nominationService } from "../../services/nomination";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { NominateBrotherDialog } from "./NominateBrother";
import { NominationsTable } from "../nominations/NominationsTable";
import { RolesTable } from "./RolesTable";

const BrotherProfile = () => {
    const nanoid = customAlphabet(nolookalikes, 24);
    const params = useParams();

    const [nominations, setNominations] = useState<Nomination[]>();
    const [roles, setRoles] = useState<Role[]>([]);
    const [brother, setBrother] = useState<Brother | null>();
    const [startcode, setStartcode] = useState<string | null>();
    const [resettoken, setResettoken] = useState<string | null>();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const brother = await brotherService.findById(params.brotherId || "");
            setBrother(brother);
            const startcode = await brotherService.getStartcode(params.brotherId || "");
            setStartcode(startcode);
            const resettoken = await brotherService.getResettoken(params.brotherId || "");
            setResettoken(resettoken);
            const nominations = await nominationService.findByBrother(params.brotherId || "");
            setNominations(nominations);
            const roles = await brotherService.getRoles(params.brotherId || "");
            setRoles(roles);
        };
        fetchData();
    }, [params.brotherId]);

    const handleSetStartcode = async () => {
        const startcode = nanoid();

        const cmd: Command<SetStartcodeCommandData> = {
            type: BrotherCommands.SetStartCode,
            data: {
                brotherId: brother?.brotherId || "",
                startcode,
            },
        };

        const { data } = await brotherService.setStartcode(cmd);
        if (data.success) {
            setStartcode(startcode);
        }
    };

    const handleSetResettoken = async () => {
        const resetToken = nanoid();

        const cmd: Command<SetResetTokenCommandData> = {
            type: BrotherCommands.SetResetToken,
            data: {
                brotherId: brother?.brotherId || "",
                resetToken,
            },
        };

        const { data } = await brotherService.setResettoken(cmd);
        if (data.success) {
            setResettoken(resetToken);
        }
    };

    const handleRoleChanged = async () => {
        const roles = await brotherService.getRoles(params.brotherId || "");
        setRoles(roles);
    };

    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row align-items-center justify-content-center surface-100">
                <h2>{`${brother?.lastname}, ${brother?.firstname}`}</h2>
            </div>
            <h3>Registrierung und Anmeldung:</h3>
            <div className="flex flex-row align-items-center m-1 flex-wrap">
                <div className="w-7rem font-semibold text-lg mr-2">Startcode:</div>
                <div className="w-20rem my-1 mr-2 border-bottom-1">{startcode}</div>

                <Button className="p-button-text" icon="pi pi-refresh" onClick={handleSetStartcode} />
                <Button
                    className="p-button-text"
                    icon="pi pi-external-link"
                    onClick={() => window.open(`${appConfig.registerUrl}/${startcode}`, "_blank")}
                />
            </div>
            <div className="flex flex-row align-items-center m-1 flex-wrap">
                <div className="w-7rem font-semibold text-lg mr-2">Resettoken:</div>
                <div className="w-20rem my-1 mr-2 border-bottom-1">{resettoken}</div>

                <Button className="p-button-text" icon="pi pi pi-refresh" onClick={handleSetResettoken} />
                <Button
                    className="p-button-text"
                    icon="pi pi-external-link"
                    onClick={() => window.open(`${appConfig.resetUrl}/${resettoken}`, "_blank")}
                />
            </div>
            <Divider />
            <h3>Ernennungen:</h3>
            <div className="flex flex-row  m-1 ">
                <Button
                    label="Neu"
                    icon="pi pi-plus"
                    iconPos="left"
                    className="p-button-link"
                    onClick={() => setVisible(true)}
                ></Button>
                <NominateBrotherDialog
                    brotherId={params.brotherId}
                    visible={visible}
                    onHide={async () => {
                        setVisible(false);
                        const nominations = await nominationService.findByBrother(params.brotherId || "");
                        setNominations(nominations);
                    }}
                />
            </div>
            <div className="flex m-3">
                <NominationsTable nominations={nominations!} allowRemove={true} />
            </div>
            <Divider />
            <h3>Rollen:</h3>
            <div className="flex m-3 ">
                <RolesTable roles={roles} brother={brother} onChanged={handleRoleChanged} />
            </div>
        </div>
    );
};

export { BrotherProfile };
