import {
    Assignment,
    AssignmentCommands,
    Brother,
    Command,
    Nomination,
    RevokeBrotherCommandData,
    Schedule,
} from "@cos/core";
import {
    addDays,
    addMonths,
    endOfDay,
    endOfMonth,
    format,
    isAfter,
    isBefore,
    startOfDay,
    startOfMonth,
    toDate,
} from "date-fns";
import { Nullable } from "primereact/ts-helpers";
import { useEffect, useState } from "react";
import { scheduleService } from "../../services/schedule";
import { assignmentService } from "../../services/assignment";
import { Calendar } from "primereact/calendar";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import _ from "lodash";
import { MenuItem } from "primereact/menuitem";
import { nominationService } from "../../services/nomination";
import { AssignedCard } from "../assignments/AssignedCard";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useParams } from "react-router-dom";
import { brotherService } from "../../services/brother";

const BrotherAssignmentsPage = () => {
    const params = useParams();
    const from = startOfDay(Date.now());
    const to = endOfMonth(endOfDay(addDays(Date.now(), 90)));
    const [schedules, setSchedules] = useState<Schedule[]>();
    const [assignments, setAssignments] = useState<Assignment[]>();
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>([from, to]);
    const [nominations, setNominations] = useState<Nomination[]>([]);
    const [brother, setBrother] = useState<Brother | null>();

    const [groups, setGroups] = useState<Date[]>();

    //const user: Brother = JSON.parse(localStorage.getItem("user") || "");

    useEffect(() => {
        const from = startOfDay(Date.now());
        const to = endOfMonth(endOfDay(addDays(Date.now(), 90)));
        setDates([from, to]);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!params.brotherId) return;
            const brother = await brotherService.findById(params.brotherId);
            if (!brother) return;
            setBrother(brother);

            const schedules = await scheduleService.findAll();
            setSchedules(schedules);
            let assignments = await assignmentService.findByBrotherId(brother?.brotherId);
            assignments = assignments.filter(
                y =>
                    isAfter(
                        y.start,
                        startOfDay(dates ? (dates[0] ? dates[0] : startOfDay(Date.now())) : startOfDay(Date.now())),
                    ) &&
                    isBefore(
                        y.start,
                        endOfDay(
                            dates
                                ? dates[1]
                                    ? dates[1]
                                    : endOfDay(addDays(Date.now(), 90))
                                : endOfDay(addDays(Date.now(), 90)),
                        ),
                    ),
            );
            setAssignments(assignments);
            const dateGroups = _.uniq(assignments.map(y => startOfDay(new Date(y.start)).valueOf()));
            setGroups(_.orderBy(dateGroups.map(y => toDate(y))));
            const nominations = await nominationService.findByBrother(brother?.brotherId);
            setNominations(nominations);
        };
        fetchData();
    }, [dates, params.brotherId]);

    const handleSetToday = () => {
        const startDate = startOfDay(Date.now());
        const endDate = endOfDay(Date.now());
        setDates([startDate, endDate]);
    };

    const handleSetCurrentMonth = () => {
        const startDate = startOfDay(Date.now());
        const endDate = endOfDay(endOfMonth(Date.now()));
        setDates([startDate, endDate]);
    };

    const handleSetNextMonth = () => {
        const startDate = startOfDay(startOfMonth(addMonths(Date.now(), 1)));
        const endDate = endOfDay(endOfMonth(startDate));
        setDates([startDate, endDate]);
    };

    const calendarModel: MenuItem[] = [
        {
            label: "Heute",
            icon: "pi pi-calendar",
            command: handleSetToday,
        },
        {
            label: "Aktueller Monat",
            icon: "pi pi-calendar",
            command: handleSetCurrentMonth,
        },
        {
            label: "Nächster Monat",
            icon: "pi pi-calendar",
            command: handleSetNextMonth,
        },
    ];

    const onRevoke = (assignment: Assignment) => {
        const accept = async () => {
            const cmd: Command<RevokeBrotherCommandData> = {
                type: AssignmentCommands.RevokeBrother,
                data: {
                    assignmentId: assignment.assignmentId,
                    brotherId: params.brotherId || "",
                },
            };

            const result = await assignmentService.revokeBrother(cmd);
            if (result.data?.success) {
                setAssignments(assignments?.filter(y => y.assignmentId !== assignment?.assignmentId));
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du diese Zuteilung absagen?`,
            header: "Absage bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Ja",
            rejectLabel: "Nein",
            accept,
            reject,
        });
    };

    return (
        <div className="flex flex-column">
            <div className="flex flex-row justify-content-center surface-ground py-2 mb-2 text-xl text-primary font-semibold">
                Zuteilungen von {`${brother?.firstname} ${brother?.lastname}`}
            </div>
            <div className="card flex justify-content-center">
                <p>Zeitraum</p>
                <Calendar
                    className="mx-2 w-13rem"
                    value={dates}
                    onChange={e => setDates(e.value)}
                    selectionMode="range"
                    locale="de"
                    readOnlyInput
                    hideOnRangeSelection
                />
                <SplitButton
                    className="p-button-text"
                    onClick={handleSetToday}
                    model={calendarModel}
                    icon="pi pi-calendar"
                ></SplitButton>
            </div>
            <ConfirmDialog />
            <Accordion activeIndex={0} className="my-2">
                {groups?.map((y, idx) => (
                    <AccordionTab key={idx} header={format(y, "dd.MM.yyyy")} headerClassName="text-xl">
                        {_.orderBy(
                            assignments?.filter(
                                assignment =>
                                    isAfter(assignment.start, startOfDay(y)) && isBefore(assignment.start, endOfDay(y)),
                            ),
                            ["start"],
                        ).map(assigment => {
                            const schedule = schedules?.find(y => y.scheduleId === assigment.schedule?.scheduleId);
                            return (
                                <AssignedCard
                                    key={assigment.assignmentId}
                                    assignment={assigment}
                                    brotherId={params.brotherId || ""}
                                    schedule={schedule}
                                    nominations={nominations}
                                    onRevoke={onRevoke}
                                />
                            );
                        })}
                    </AccordionTab>
                ))}
            </Accordion>
        </div>
    );
};

export { BrotherAssignmentsPage };
