import { Assignment, Nomination, Schedule } from "@cos/core";
import { format } from "date-fns";
import { Button } from "primereact/button";

import { Nullable } from "primereact/ts-helpers";
import { useState } from "react";

import { nominationService } from "../../services/nomination";
import { ExchangeDialog } from "./ExchangeDialog";

type AssignedCardProps = {
    schedule: Nullable<Schedule>;
    assignment: Assignment;
    brotherId: string;
    nominations: Nomination[] | null;
    onRevoke: (assigment: Assignment) => void;
};
const AssignedCard = (props: AssignedCardProps) => {
    const [visible, setVisible] = useState(false);
    const [nominations, setNominations] = useState<Nomination[]>();

    const handleExchange = async () => {
        const nominations = await nominationService.findByScheduleId(props.assignment.schedule?.scheduleId || "");
        setNominations(nominations);
        setVisible(true);
    };

    return (
        <>
            <ExchangeDialog
                brotherId={props.brotherId}
                assignment={props.assignment}
                nominations={nominations}
                visible={visible}
                onHide={() => setVisible(false)}
            ></ExchangeDialog>
            <div className="flex flex-row w-full m-1 shadow-1 border-1 py-2">
                <div className="align-self-center text-2xl mx-3 font-bold">
                    {`${format(props.assignment.start, "HH:mm")} - ${format(props.assignment.end, "HH:mm")} `}
                </div>
                <div className="flex flex-row w-full">
                    <div className="flex flex-column w-full">
                        <div className="font-semibold">{props.schedule?.title}</div>
                        <div>{props.assignment.part.name}</div>
                        <div>
                            {props.assignment.assignedTo?.find(y => y.brother.brotherId === props.brotherId)?.text}
                        </div>
                    </div>
                    <div className="flex flex-column">
                        <Button
                            className="p-button-text mr-4"
                            icon="pi pi-users text-2xl font-bold"
                            onClick={() => handleExchange()}
                        />
                        <Button
                            className="p-button-text mr-4"
                            icon="pi pi-user-minus text-2xl font-bold"
                            onClick={() => props.onRevoke(props.assignment)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export { AssignedCard };
