import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { KeyboardEvent, useState } from "react";

import { authService } from "../../services/auth";
import { useNavigate } from "react-router-dom";

const SigninPage = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ email: undefined, password: undefined });

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };

    const handleLogin = async () => {
        const { email = "", password = "" } = data;
        const success = await authService.signin(String(email).trim().toLowerCase(), String(password).trim());
        if (success) {
            navigate("/");
        }
    };

    const handleKeyDown = async (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            await handleLogin();
        }
    };

    return (
        <div className="flex flex-column h-screen justify-content-center align-items-center">
            <Card title="CO Servant" subTitle="Anmelden" className="lg:w-4 md:w-8 sm:w-10">
                <div className="grid m-0 ">
                    <div className="field w-full ">
                        <label htmlFor="email">Email</label>
                        <InputText name="email" className="w-10 block" value={data?.email} onChange={handleChange} />
                    </div>
                    <div className="field w-full  ">
                        <label htmlFor="password">Kennwort</label>
                        <Password
                            name="password"
                            className="w-10 block"
                            value={data?.password}
                            onChange={handleChange}
                            toggleMask
                            feedback={false}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                <div className="flex flex-row">
                    <Button label="Anmelden" onClick={handleLogin} />
                </div>
            </Card>
            <div className="footer">
                <a href="./impressum">
                    <div className="text-xs">Impressum</div>
                </a>
            </div>
        </div>
    );
};

export { SigninPage };
