import { isRouteErrorResponse, useRouteError } from "react-router-dom";
interface RouterError extends Error {}

function errorMessage(error: unknown): string {
    if (isRouteErrorResponse(error)) {
        return `${error.status} ${error.statusText}`;
    } else if (error != undefined && isRouterError(error)) {
        return error.message;
    } else if (typeof error === "string") {
        return error;
    } else {
        console.error(error);
        return "Unknown error";
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRouterError(object: any): object is RouterError {
    return "message" in object;
}

const ErrorPage = () => {
    const error = errorMessage(useRouteError());

    console.error(error);

    return (
        <div>
            <h1>Uups!</h1>
            <p>Entschuldige bitte, da ist ein unerwarteter Fehler aufgetreten...</p>
            <p>
                <i>{error}</i>
            </p>
        </div>
    );
};

export { ErrorPage };
