import { AddNewAssignmentCommandData, AssignmentCommands, Command, SchedulePart } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import { useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import _ from "lodash";
import { assignmentService } from "../../services/assignment";
import { schedulePartService } from "../../services/schedulePart";
import { Calendar } from "primereact/calendar";
import { addHours, startOfToday } from "date-fns";
import { InputNumber } from "primereact/inputnumber";

type AddNewAssignmentProps = {
    scheduleId: string;
    visible: boolean;
    onHide: () => void;
};

const AddNewAssignmentDialog = (props: AddNewAssignmentProps) => {
    const { visible, onHide } = props;
    const [data, setData] = useState<{
        schedulePart?: SchedulePart;
        min?: number;
        max?: number;
        nomination?: string;
        start?: string;
        end?: string;
    }>();

    const [scheduleParts, setScheduleParts] = useState<SchedulePart[]>();

    useEffect(() => {
        const fetchData = async () => {
            const scheduleParts = await schedulePartService.findAll(props.scheduleId);
            setScheduleParts(scheduleParts);
        };
        fetchData();
    }, [props.scheduleId]);

    const handleSubmit = async () => {
        const cmd: Command<AddNewAssignmentCommandData> = {
            type: AssignmentCommands.AddNewAssignment,
            data: {
                assignmentId: uuidV4(),
                scheduleId: props.scheduleId,
                start: data?.start || "",
                end: data?.end || "",
                min: data?.min || 1,
                max: data?.max || 1,
                schedulePartId: data?.schedulePart?.schedulePartId || null,
            },
        };

        const result = await assignmentService.addNewAssignment(cmd);
        if (result.data.success) {
            handleHide();
        }
    };

    const handleHide = () => {
        setData(undefined);
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Einsatz hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-30rem"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field col-12">
                            <label htmlFor="schedulePart" className="block">
                                Zuteilung
                            </label>
                            <Dropdown
                                id="schedulePart"
                                name="schedulePart"
                                className="w-full"
                                value={data?.schedulePart}
                                optionLabel="name"
                                options={_.orderBy<SchedulePart>(scheduleParts, "name")}
                                onChange={handleChange}
                            />
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="start" className="block">
                                Einsatz von
                            </label>
                            <Calendar
                                className="w-full"
                                touchUI
                                dateFormat={"dd.mm.yy"}
                                showTime
                                hourFormat="24"
                                value={new Date(data?.start || addHours(startOfToday(), 10))}
                                onChange={e => setData({ ...data, start: e.value?.toJSON() })}
                            />
                        </div>

                        <div className="field col-12 md:col-6">
                            <label htmlFor="end" className="block">
                                Einsatz bis
                            </label>
                            <Calendar
                                className="w-full"
                                touchUI
                                dateFormat={"dd.mm.yy"}
                                showTime
                                hourFormat="24"
                                value={new Date(data?.end || addHours(startOfToday(), 12))}
                                onChange={e => setData({ ...data, end: e.value?.toJSON() })}
                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="min" className="block">
                                Min
                            </label>
                            <InputNumber
                                className="w-full"
                                value={data?.min || 1}
                                min={1}
                                step={1}
                                showButtons
                                onChange={e => setData({ ...data, min: e.value || 1 })}
                            />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="max" className="block">
                                Max
                            </label>
                            <InputNumber
                                className="w-full"
                                value={data?.max || 1}
                                min={1}
                                max={999}
                                step={1}
                                maxFractionDigits={0}
                                showButtons
                                onChange={e => setData({ ...data, max: e.value || 1 })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex col-12 flex-row justify-content-end ">
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-text"></Button>
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewAssignmentDialog };
