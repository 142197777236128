import { Route, Routes } from "react-router-dom";
import { OptionPage } from "./components/options/OptionPage";
import { Homepage } from "./components/pages/Homepage";
import { ProtectedRoute } from "./components/common/ProtectedRoute";
import { AppMenu } from "./components/navigation/AppMenu";
import { MyPage } from "./components/pages/MyPage";
import { ImpressumPage } from "./Impressum";

const App = () => {
    return (
        <>
            <AppMenu />

            <Routes>
                <Route
                    path="/home"
                    element={
                        <ProtectedRoute>
                            <Homepage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/my"
                    element={
                        <ProtectedRoute>
                            <MyPage />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/option/*"
                    element={
                        <ProtectedRoute>
                            <OptionPage />
                        </ProtectedRoute>
                    }
                />
                <Route path="/impressum" element={<ImpressumPage />} />
            </Routes>
        </>
    );
};
export default App;
