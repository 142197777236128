import { Column, ColumnEditorOptions, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta, DataTableRowEditCompleteEvent } from "primereact/datatable";

import { useEffect, useState } from "react";

import {
    AddNewAssignmentCommandData,
    Assignment,
    AssignmentCommands,
    ChangeAssignmentCommandData,
    Command,
    RemoveAssignmentCommandData,
    Schedule,
    SchedulePart,
} from "@cos/core";
import { assignmentService } from "../../services/assignment";
import { Calendar } from "primereact/calendar";
import { addSeconds, differenceInSeconds, format } from "date-fns";
import { Button } from "primereact/button";
import { AddNewAssignmentDialog } from "./AddNewAssignment";
import { scheduleService } from "../../services/schedule";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { v4 as uuidV4 } from "uuid";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { Chip } from "primereact/chip";
import { FilterMatchMode } from "primereact/api";

type propsType = {
    scheduleId: string;
    assignments: Assignment[];
    allowEdit: boolean;
};

const AssignmentsTable = (props: propsType) => {
    const navigate = useNavigate();
    const [data, setData] = useState<Assignment[]>();

    const [addDialogVisibile, setAddDialogVisisble] = useState(false);
    const [schedule, setSchedule] = useState<Schedule | null>();
    const [filters] = useState<DataTableFilterMeta>({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "part.schedulePartId": { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const fetchData = async () => {
        const schedule = await scheduleService.findById(props.scheduleId);
        setSchedule(schedule);
        const assignments = await assignmentService.findAll(props.scheduleId);
        setData(assignments);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleClone = async (row: Assignment) => {
        const cmd: Command<AddNewAssignmentCommandData> = {
            type: AssignmentCommands.AddNewAssignment,
            data: {
                assignmentId: uuidV4(),
                max: row.max,
                min: row.min,
                start: row.end,
                end: addSeconds(row.end, differenceInSeconds(row.end, row.start)).toJSON(),
                scheduleId: props.scheduleId,
                schedulePartId: row.part.schedulePartId,
            },
        };
        const result = await assignmentService.addNewAssignment(cmd);
        if (result.data.success) {
            const assignments = await assignmentService.findAll(props.scheduleId);
            setData(assignments);
        }
    };

    const handleDelete = async row => {
        confirmRemove(row);
    };

    const confirmRemove = row => {
        const accept = async () => {
            const cmd: Command<RemoveAssignmentCommandData> = {
                type: AssignmentCommands.RemoveAssignment,
                data: {
                    assignmentId: row.assignmentId,
                },
            };
            const result = await assignmentService.removeAssignment(cmd);
            if (result.data.success) {
                const assignments = await assignmentService.findAll(props.scheduleId);
                setData(assignments);
            }
        };
        const reject = () => {};
        confirmDialog({
            message: `Möchtest du den Eintrag '${format(row.start, "dd.MM.yyyy HH:mm")} bis ${format(row.end, "dd.MM.yyyy HH:mm")} ${row.part.name}' löschen?`,
            header: "Löschen bestätigen",
            icon: "pi pi-info-circle",
            defaultFocus: "reject",
            acceptClassName: "p-button-danger",
            acceptIcon: "pi pi-check",
            acceptLabel: "Löschen",
            rejectLabel: "Abbrechen",
            accept,
            reject,
        });
    };

    const handleRowEditCompete = async (e: DataTableRowEditCompleteEvent) => {
        const { newData } = e;

        const cmd: Command<ChangeAssignmentCommandData> = {
            type: AssignmentCommands.ChangeAssignment,
            data: {
                assignmentId: newData.assignmentId,
                start: newData.start,
                end: newData.end,
                min: newData.min,
                max: newData.max,
                schedulePartId: newData.part.schedulePartId,
            },
        };

        const result = await assignmentService.changeAssignment(cmd);
        if (result.data.success) {
            const assignments = await assignmentService.findAll(props.scheduleId);
            setData(assignments);
        }
    };

    const dateEditor = (options: ColumnEditorOptions) => {
        return (
            <Calendar
                dateFormat={"dd.mm.yy"}
                showTime
                hourFormat="24"
                value={new Date(options.value)}
                onChange={e => options.editorCallback!(e.value?.toJSON())}
            />
        );
    };

    const schedulePartEditor = (options: ColumnEditorOptions) => {
        return (
            <Dropdown
                options={schedule?.parts}
                optionLabel="name"
                value={options.value}
                onChange={e => options.editorCallback!(e.value)}
            />
        );
    };

    const handleHideAddDialog = async () => {
        const data = await assignmentService.findAll(props.scheduleId);
        setData(data);
        setAddDialogVisisble(false);
    };

    const tableHeader = (
        <div className="flex flex-row w-full justify-content-between align-items-center">
            <div className="flex flex-colum">{`${schedule?.title}`}</div>

            <div className="flex flex-row justify-content-end">
                <Button
                    icon="pi pi-refresh text-2xl font-bold"
                    label="Aktualisieren"
                    className="p-button-text"
                    onClick={() => fetchData()}
                />

                <Button
                    icon="pi pi-plus text-2xl font-bold"
                    label="Neu"
                    className="p-button-text"
                    onClick={() => setAddDialogVisisble(true)}
                />
                <AddNewAssignmentDialog
                    visible={addDialogVisibile}
                    scheduleId={props.scheduleId}
                    onHide={handleHideAddDialog}
                />
                <ConfirmDialog />
            </div>
        </div>
    );

    const rowItemTemplate = (option: SchedulePart) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const partRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
        return (
            <Dropdown
                value={options.value}
                itemTemplate={rowItemTemplate}
                options={schedule?.parts}
                onChange={(e: DropdownChangeEvent) => options.filterApplyCallback(e.value)}
                optionLabel="name"
                optionValue="schedulePartId"
                className="p-column-filter"
                showClear
            />
        );
    };
    return (
        <>
            <DataTable
                header={tableHeader}
                value={data}
                size={"small"}
                rows={10}
                className="w-full h-max my-"
                editMode="row"
                onRowEditComplete={handleRowEditCompete}
                dataKey="assignmentId"
                sortMode="multiple"
                paginator
                scrollable
                filters={filters}
                filterDisplay="row"
                rowsPerPageOptions={[10, 20, 50]}
                sortField="start"
            >
                <Column
                    body={row => (
                        <div className="flex flex-row">
                            <Button
                                icon="pi pi-users text-2xl font-bold"
                                className="p-button-text"
                                onClick={() => navigate(`./assignedTo/${row.assignmentId}`)}
                            />
                        </div>
                    )}
                    headerClassName="w-3rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    field="start"
                    className="w-auto"
                    editor={options => dateEditor(options)}
                    header="Von"
                    sortable
                    body={row => row.start && <div>{format(Date.parse(row.start), "dd.MM.yyyy HH:mm")}</div>}
                    alignFrozen="left"
                ></Column>
                <Column
                    field="end"
                    className="w-auto"
                    editor={options => dateEditor(options)}
                    header="Bis"
                    sortable
                    body={row => row.start && <div>{format(Date.parse(row.end), "dd.MM.yyyy HH:mm")}</div>}
                    alignFrozen="left"
                ></Column>
                <Column
                    field="part.schedulePartId"
                    header="Zuteilung"
                    filter
                    filterElement={partRowFilterTemplate}
                    showFilterMenu={false}
                    editor={options => schedulePartEditor(options)}
                    body={row => (row.part ? <div>{row.part?.name}</div> : null)}
                />
                <Column field="id" header="" body={row => <AssignStateChip data={row} />} />
                <Column
                    rowEditor={props.allowEdit}
                    headerStyle={{ width: "10%", minWidth: "8rem" }}
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
                <Column
                    body={row => {
                        return (
                            <div className="flex flex-row">
                                <Button
                                    icon="pi pi-clone text-2xl font-bold"
                                    className=" p-button-text "
                                    onClick={() => handleClone(row)}
                                />

                                <Button
                                    icon="pi pi-trash text-2xl font-bold"
                                    className="p-button-text "
                                    onClick={() => handleDelete(row)}
                                />
                            </div>
                        );
                    }}
                    headerClassName="w-4rem"
                    bodyStyle={{ textAlign: "center" }}
                ></Column>
            </DataTable>
        </>
    );
};

type AssignStateChipProps = {
    data: Assignment;
};
const AssignStateChip = (props: AssignStateChipProps) => {
    const [status, setStatus] = useState<string>("assignable");
    const [open, setOpen] = useState<number>(0);

    useEffect(() => {
        const assigned = props.data.assignedTo.filter(y => y.brother.brotherId != null).length;
        if (assigned < props.data.max) {
            setStatus("assignable");
            setOpen(props.data.max - assigned);
        }
        if (assigned >= props.data.max) {
            setStatus("complete");
            setOpen(0);
        }
    }, [props.data.assignedTo, props.data.max]);

    switch (status) {
        case "assignable":
            return <Chip icon="pi pi-minus" className="bg-red-500 m-1 text-white" label={`${open}`} />;
        default:
            return <Chip icon="pi pi-check" className="bg-green-500 m-1 text-white" label="ok" />;
    }
};

export { AssignmentsTable };
