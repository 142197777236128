import { Outlet, useNavigate } from "react-router-dom";
import { authService } from "../../services/auth";
import { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ProtectedRoute = ({ redirectPath = "/signin", children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const checkSignin = async () => {
            const isSignin = await authService.checkSignin();

            if (!isSignin) {
                navigate(redirectPath, { replace: true });
            }
        };
        checkSignin();
    }, []);

    return children ? children : <Outlet />;
};
