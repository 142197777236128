import { Link } from "react-router-dom";

const ImpressumPage = () => {
    return (
        <div className="flex flex-column m-3 ">
            <div className="m-4">
                <Link to="/">Zurück auf die Startseite</Link>
            </div>
            <h1>Impressum</h1>
            <h2>Angaben gemäß § 5 TMG:</h2>
            <p>Thomas Dörr</p>
            <h3>Postanschrift:</h3>
            <p>Westerholter Str. 72 45768 Marl</p>
            <h3>Kontakt:</h3>
            <p>E-Mail: web@t-doerr.de</p>
            <div className="md:w-6 sm:w-9 text-justify">
                <h1>Datenschutzerklärung</h1>
                <h2>
                    I. Informationen über die Verarbeitung deiner Daten gemäß Art. 13 der Datenschutz-Grundverordnung
                </h2>
                <h3> 1. Verantwortlicher und Datenschutzbeauftragter</h3>
                <p>
                    Verantwortlich für diese Website ist Thomas Dörr, Westerholter Str. 72, 45768 Marl, web@t-doerr.de
                </p>
                <h3>
                    2. Daten, die für die Bereitstellung der Website und die Erstellung der Protokolldateien verarbeitet
                    werden
                </h3>
                <h4>a. Welche Daten werden für welchen Zweck verarbeitet? </h4>
                <p>Wir setzen keinerlei externe Tracking- und Analysetools ein.</p>
                <p>
                    Verarbeitet werden dein Vorname, Name, sowie deine Termine, Zuteilungen zu Schichten und Diensten.
                    Für die Anmeldung ist ein Benutzername (E-Mail) und ein Kennwort erforderlich. Siehe dazu auch die
                    dir bekannten Formulare S-290 und S-291.
                </p>
                <h4>b. Auf welcher Rechtsgrundlage werden diese Daten verarbeitet? </h4>
                <p>Die Daten werden auf der Grundlage des Art. 6 Abs. 1 Buchstabe f DS-GVO verarbeitet. </p>
                <h4>c. Gibt es neben dem Verantwortlichen weitere Empfänger der personenbezogenen Daten? </h4>
                <p>Nein.</p>
                <h4> d. Wie lange werden die Daten gespeichert? </h4>
                <p>
                    Die Daten werden gelöscht, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht mehr
                    erforderlich sind.
                </p>
                <h3>3. Betroffenenrechte </h3>
                <h4>a. Recht auf Auskunft</h4>
                Du kannst Auskunft nach Art. 15 DS-GVO über deine personenbezogenen Daten verlangen, die wir
                verarbeiten.
                <h4>b. Recht auf Widerspruch</h4>
                <p> Du hast ein Recht auf Widerspruch aus besonderen Gründen (siehe unter Punkt II).</p>
                <h4> c. Recht auf Berichtigung </h4>
                <p>
                    Sollten die dich betreffenden Angaben nicht (mehr) zutreffend sein, kannst du nach Art. 16 DS-GVO
                    eine Berichtigung verlangen. Sollten deine Daten unvollständig sein, kannst du eine
                    Vervollständigung verlangen.
                </p>
                <h4> d. Recht auf Löschung</h4>
                <p>Du kannst nach Art. 17 DS-GVO die Löschung deiner personenbezogenen Daten verlangen.</p>
                <h4>e. Recht auf Einschränkung der Verarbeitung</h4>
                <p>
                    Du hast nach Art. 18 DS-GVO das Recht, eine Einschränkung der Verarbeitung deiner personenbezogenen
                    Daten zu verlangen.
                </p>
                <h4>f. Recht auf Beschwerde</h4>
                <p>
                    Wenn Du der Ansicht bist, dass die Verarbeitung deiner personenbezogenen Daten gegen
                    Datenschutzrecht verstößt, hast du nach Ar. 77 Abs. 1 DS-GVO das Recht, sich bei einer
                    Datenschutzaufsichtsbehörde eigener Wahl zu beschweren. Hierzu gehört auch die für den
                    Verantwortlichen zuständige Datenschutzaufsichtsbehörde: Landesbeauftragte für Datenschutz und
                    Informationsfreiheit Nordrhein-Westfalen, https://www.ldi.nrw.de/kontakt/ihre-beschwerde.
                </p>
                <h4>g. Recht auf Datenübertragbarkeit </h4>
                <p>
                    Für den Fall, dass die Voraussetzungen des Art. 20 Abs. 1 DS-GVO vorliegen, steht dir das Recht zu,
                    sich Daten, die wir auf Grundlage deiner Einwilligung oder in Erfüllung eines Vertrags automatisiert
                    verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Erfassung der Daten zur
                    Bereitstellung der Website und die Speicherung der Protokolldateien sind für den Betrieb der
                    Internetseite zwingend erforderlich. Sie beruhen daher nicht auf einer Einwilligung nach Art. 6 Abs.
                    1 Buchstabe a DS-GVO oder auf einem Vertrag nach Art. 6 Abs. 1 Buchstabe b DS-GVO, sondern sind nach
                    Art. 6 Abs. 1 Buchstabe f DS-GVO gerechtfertigt. Die Voraussetzungen des Art. 20 Abs. 1 DSGVO sind
                    demnach insoweit nicht erfüllt.
                </p>
                <h2>II. Recht auf Widerspruch gemäß Art. 21 Abs. 1 DS-GVO</h2>
                <p>
                    Du hast das Recht, aus Gründen, die sich aus deiner besonderen Situation ergeben, jederzeit gegen
                    die Verarbeitung deiner personenbezogenen Daten, die aufgrund von Artikel 6 Abs. 1 Buchstabe f
                    DS-GVO erfolgt, Widerspruch einzulegen. Der Verantwortliche verarbeitet die personenbezogenen Daten
                    dann nicht mehr, es sei denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung
                    nachweisen, die die Interessen, Rechte und Freiheiten der betroffenen Person überwiegen, oder die
                    Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Die
                    Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Protokolldateien sind für
                    den Betrieb der Internetseite zwingend erforderlich.
                </p>
            </div>
            <div className="m-4">
                <Link to="/">Zurück auf die Startseite</Link>
            </div>
        </div>
    );
};

export { ImpressumPage };
