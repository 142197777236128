import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { useEffect, useState } from "react";
import { brotherService } from "../../services/brother";
import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../services/auth";

type formsData = {
    startcode?: string;
    brotherId?: string;
    email?: string;
    password?: string;
    password2?: string;
};

type SelectedBrother = {
    brotherId: string;
    fullname: string;
};
const RegisterPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<formsData>({ startcode: params.code });
    const [brother, setBrother] = useState<SelectedBrother>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const brothers = await brotherService.findByStartcode(params.code || "");
            setLoading(false);
            if (brothers.length === 1) {
                const b = {
                    brotherId: brothers[0].brotherId,
                    fullname: `${brothers[0].lastname}, ${brothers[0].firstname}`,
                };
                setBrother(b);
                return;
            }
            setBrother(undefined);
        };

        if (params.code && params.code.length === 24) {
            setLoading(true);
            fetchData();
        }
    }, []);

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };

    const handleRegister = async () => {
        if (data && brother?.brotherId && data.email && data.password && params.code) {
            if (data?.password === data?.password2)
                if (await authService.register(brother.brotherId, data.email, data.password, params.code)) {
                    navigate("/", { replace: true });
                }
        }
    };

    return (
        <div className="flex flex-column h-screen justify-content-center align-items-center">
            <Card title="CO Servant" subTitle="Registrierung" className="lg:w-3 md:w-6 sm:w-11">
                <div className="grid m-0">
                    <div className="field w-full ">
                        <label htmlFor="startcode">Startcode</label>

                        <InputText name="startcode" className="w-11 " value={data?.startcode} onChange={handleChange} />
                    </div>
                </div>

                {loading && <i className="pi pi-spin pi-spinner-dotted text-primary" style={{ fontSize: "2rem" }}></i>}
                {brother && (
                    <>
                        <div className="grid m-0 ">
                            <div className="field w-full ">
                                <div>{brother.fullname}</div>
                            </div>
                            <div className="field w-full ">
                                <label htmlFor="email">Email</label>
                                <InputText
                                    name="email"
                                    className="w-full "
                                    value={data?.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="field w-full  ">
                                <label htmlFor="password">Kennwort</label>
                                <Password
                                    name="password"
                                    className="w-12"
                                    value={data?.password}
                                    onChange={handleChange}
                                    toggleMask
                                    feedback={false}
                                />
                            </div>
                            <div className="field w-full  ">
                                <label htmlFor="password2">Kennwort Wiederholung</label>
                                <Password
                                    name="password2"
                                    className="w-12"
                                    value={data?.password2}
                                    onChange={handleChange}
                                    toggleMask
                                    feedback={false}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <Button label="Registrieren" onClick={handleRegister} />
                        </div>
                    </>
                )}
            </Card>
        </div>
    );
};

export { RegisterPage };
