import { SchedulePart } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";

const AddNewSchedulePartDialog = props => {
    const { visible, onHide, scheduleId } = props;
    const [data, setData] = useState<Partial<SchedulePart>>({
        schedulePartId: uuidV4(),
        scheduleId,
        name: "",
        position: 1,
    });

    const handleSubmit = () => {
        const schedulePart = { ...data };
        setData({
            schedulePartId: uuidV4(),
            scheduleId,
            name: "",
            position: 1,
        });
        props.onSubmit(schedulePart);
    };

    const handleHide = () => {
        setData({
            schedulePartId: uuidV4(),
            scheduleId,
            name: "",
            position: 1,
        });
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    const handleChangePosition = e => {
        const newData = { ...data, ["position"]: e.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Teilplan hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-5"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="name" className="block">
                                Name
                            </label>
                            <InputText
                                className="w-full"
                                id="name"
                                name="name"
                                value={data.name}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="position" className="block">
                                Position
                            </label>
                            <InputNumber
                                min={1}
                                maxFractionDigits={0}
                                step={1}
                                showButtons={true}
                                className="w-full"
                                id="position"
                                name="position"
                                value={data.position}
                                onChange={handleChangePosition}
                            ></InputNumber>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewSchedulePartDialog };
