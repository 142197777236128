import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { addLocale, PrimeReactProvider } from "primereact/api";
import { ErrorPage } from "./components/pages/ErrorPage.tsx";

import { Layout } from "./components/pages/Layout.tsx";

import "primereact/resources/themes/saga-purple/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css"; // flex

import { SigninPage } from "./components/auth/SigninPage.tsx";
import { RegisterPage } from "./components/auth/RegisterPage.tsx";
import { ResetPage } from "./components/auth/ResetPage.tsx";

import { locale } from "primereact/api";
import { ImpressumPage } from "./Impressum.tsx";

addLocale("de", {
    accept: "Ja",
    addRule: "Regel hinzufügen",
    am: "am",
    apply: "Übernehmen",
    cancel: "Abbrechen",
    choose: "Auswählen",
    chooseDate: "Datum wählen",
    chooseMonth: "Monat wählen",
    chooseYear: "Jahr wählen",
    clear: "Löschen",

    contains: "Enthält",
    custom: "Benutzerdefiniert",
    dateAfter: "Datum ist nach",
    dateBefore: "Datum ist vor",
    dateFormat: "dd.mm.yy",
    dateIs: "Datum ist",
    dateIsNot: "Datum ist nicht",
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    emptyFilterMessage: "Keine Ergebnisse gefunden",
    emptyMessage: "Keine Einträge gefunden",

    endsWith: "Endet mit",
    equals: "Ist gleich",
    fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    filter: "Filtern",
    firstDayOfWeek: 1,
    gt: "Größer als",
    gte: "Größer oder gleich",
    lt: "Kleiner als",
    lte: "Kleiner oder gleich",
    matchAll: "Passt auf alle",
    matchAny: "Passt auf einige",
    medium: "Mittel",
    monthNames: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
    ],
    monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    nextDecade: "Nächstes Jahrzehnt",
    nextHour: "Nächste Stunde",
    nextMinute: "Nächste Minute",
    nextMonth: "Nächster Monat",
    nextSecond: "Nächste Sekunde",
    nextYear: "Nächstes Jahr",
    noFilter: "Kein Filter",
    notContains: "Enthält nicht",
    notEquals: "Ist ungleich",
    now: "Jetzt",
    passwordPrompt: "Passwort eingeben",
    pending: "Ausstehend",
    pm: "pm",
    prevDecade: "Vorheriges Jahrzehnt",
    prevHour: "Vorherige Stunde",
    prevMinute: "Vorherige Minute",
    prevMonth: "Vorheriger Monat",
    prevSecond: "Vorherige Sekunde",
    prevYear: "Vorheriges Jahr",
    reject: "Nein",
    removeRule: "Regel entfernen",

    startsWith: "Beginnt mit",
    strong: "Stark",
    today: "Heute",
    upload: "Hochladen",
    weak: "Schwach",
    weekHeader: "KW",
});

locale("de");

const router = createBrowserRouter([
    {
        path: "*",
        element: <Layout />,
        errorElement: <ErrorPage />,
    },
    {
        path: "impressum",
        element: <ImpressumPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "signin",
        element: <SigninPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "register/*",

        element: <RegisterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "register/:code",

        element: <RegisterPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "reset/:resetToken",

        element: <ResetPage />,
        errorElement: <ErrorPage />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <PrimeReactProvider>
        <div className="page-container h-full">
            <RouterProvider router={router} />
        </div>
    </PrimeReactProvider>,
);
