import appConfig from "../app.config";
import { CommandResult } from "@cos/core";

import axios from "axios";

const AUTH_BASE_URL = appConfig.authBaseUrl;

const authService = {
    signout: async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    },
    signin: async (email: string, password: string): Promise<boolean> => {
        const cmd: { type: string; data: { email: string; password: string } } = {
            type: "SIGNIN",
            data: {
                email,
                password,
            },
        };

        const result: CommandResult = await axios.post(`${AUTH_BASE_URL}`, cmd);
        if (result.data.success) {
            localStorage.setItem("token", result.data.data?.token);
            localStorage.setItem("user", JSON.stringify(result.data.data?.user));
            return true;
        }

        await authService.signout();
        console.error(result.message);
        return false;
    },

    checkSignin: async (): Promise<boolean> => {
        const token = localStorage.getItem("token");
        if (token) {
            const cmd: { type: string; data: { token: string } } = {
                type: "VERIFY_TOKEN",
                data: {
                    token,
                },
            };

            const verifyTokenResult = await axios.post(`${AUTH_BASE_URL}`, cmd);
            if (verifyTokenResult.data?.success) {
                return true;
            }
        }
        return false;
    },

    resetPassword: async (email: string, resetToken: string, password: string) => {
        const cmd = { type: "RESET_PASSWORD", data: { email, resetToken, password } };
        const { data } = await axios.post(`${AUTH_BASE_URL}`, cmd);
        if (data?.success) {
            return data.success;
        }
    },

    register: async (brotherId: string, email: string, password: string, startcode: string) => {
        const cmd = { type: "REGISTER", data: { brotherId, email, password, startcode } };
        const { data } = await axios.post(`${AUTH_BASE_URL}`, cmd);
        if (data?.success) {
            return true;
        }
    },
};

export { authService };
