import { NominationItem } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";

const AddNewNominationItemDialog = props => {
    const { visible, onHide } = props;
    const [data, setData] = useState<NominationItem>({
        nominationItemId: uuidV4(),
        name: "",
        shortName: "",
    });

    const handleSubmit = () => {
        const congregation = { ...data };
        setData({
            nominationItemId: uuidV4(),
            name: "",
            shortName: "",
        });
        props.onSubmit(congregation);
    };

    const handleHide = () => {
        setData({
            nominationItemId: uuidV4(),
            name: "",
            shortName: "",
        });
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Ernennung hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-5"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="name" className="block">
                                Ernennung
                            </label>
                            <InputText
                                className="w-full"
                                id="name"
                                name="name"
                                value={data.name}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="shortName" className="block">
                                Kurz
                            </label>
                            <InputText
                                className="w-6rem"
                                id="shortName"
                                name="shortName"
                                value={data.shortName}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewNominationItemDialog };
