import { Brother, Congregation } from "@cos/core";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { v4 as uuidV4 } from "uuid";
import _ from "lodash";

const AddNewBrotherDialog = props => {
    const { visible, onHide } = props;
    const [data, setData] = useState<Partial<Brother>>({
        brotherId: uuidV4(),
        firstname: "",
        lastname: "",
        email: "",
        congregation: undefined,
    });

    const handleSubmit = () => {
        const brother = { ...data };
        setData({
            brotherId: uuidV4(),
            firstname: "",
            lastname: "",
            email: "",
            congregation: undefined,
        });
        props.onSubmit(brother);
    };

    const handleHide = () => {
        setData({
            brotherId: uuidV4(),
            firstname: "",
            lastname: "",
            email: "",
            congregation: undefined,
        });
        onHide();
    };

    const handleChange = e => {
        const newData = { ...data, [e.target.name]: e.target.value };
        setData(newData);
    };
    return (
        <Dialog
            header="Bruder hinzufügen"
            visible={visible}
            modal
            onHide={handleHide}
            className="w-8 h-30rem"
            position={"center"}
        >
            <div className="flex flex-column">
                <div className="flex flex-row my-3 ">
                    <div className="grid w-full">
                        <div className="field w-full">
                            <label htmlFor="firstname" className="block">
                                Vorname
                            </label>
                            <InputText
                                className="w-full"
                                id="firstname"
                                name="firstname"
                                value={data.firstname}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="lastname" className="block">
                                Name
                            </label>
                            <InputText
                                className="w-full"
                                id="lastname"
                                name="lastname"
                                value={data.lastname}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="email" className="block">
                                Email
                            </label>
                            <InputText
                                className="w-full"
                                id="email"
                                name="email"
                                value={data.email}
                                onChange={handleChange}
                            ></InputText>
                        </div>
                        <div className="field w-full">
                            <label htmlFor="congregation" className="block">
                                Versammlung
                            </label>
                            <Dropdown
                                id="congregation"
                                name="congregation"
                                className="w-full"
                                value={data.congregation}
                                optionLabel="name"
                                options={_.orderBy<Congregation>(props.congregations, "name")}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-content-end ">
                    <Button label="Speichern" onClick={handleSubmit} icon="pi pi-check" className="p-button"></Button>
                    <Button label="Abbrechen" onClick={handleHide} className="p-button-outline p-button-link"></Button>
                </div>
            </div>
        </Dialog>
    );
};

export { AddNewBrotherDialog };
