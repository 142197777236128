import axios from "axios";
import appConfig from "../app.config";

// Funktion, um den aktuellen JWT-Token aus dem Speicher zu holen
function getCurrentAccessToken(): string | null {
    return localStorage.getItem("token") || "TOKEN";
}

// Erstelle den Axios-Client mit dem Interceptor
function createAxiosClient() {
    const client = axios.create({
        baseURL: appConfig.apiBaseUrl, // Deine API-URL hier einfügen
    });

    // Request-Interceptor
    client.interceptors.request.use(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (config: any) => {
            const token = getCurrentAccessToken();
            if (token) {
                config.headers["x-access-token"] = token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        },
    );

    client.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 403) {
                localStorage.clear();
                window.location.href = "/signin";
            }
        },
    );

    return client;
}

export default createAxiosClient();
