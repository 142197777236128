import { BrothersPage } from "../brothers/BrothersPage";
import { NavCard } from "../common/NavCard";
import { CongregationsPage } from "../congregations/CongregationsPage";

import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { SchedulesPage } from "../schedules/SchedulesPage";
import { NominationItemsPage } from "../nominations/items/NominationItemsPage";
import { NominationsPage } from "../nominations/NominationsPage";

const OptionPage = () => {
    const navigate = useNavigate();
    return (
        <div className="flex flex-column">
            <div className="flex flex-row align-items-center" onClick={() => navigate(-1)}>
                <i className="pi pi-arrow-circle-left mr-2 text-3xl"></i>
                <h1>Einstellungen</h1>
            </div>
            <Routes>
                <Route
                    index
                    element={
                        <div className="flex flex-wrap justify-content-center align-content-center">
                            <NavCard to="./congregations" text="Versammlungen" icon="pi pi-home text-3xl m-2" />
                            <NavCard to="./brothers" text="Brüder" icon="pi pi-users text-3xl m-2" />
                            <NavCard to="./schedules" text="Pläne" icon="pi pi-clock text-3xl m-2" />
                            <NavCard to="./nominationItems" text="Ernennungen" icon="pi pi-tags text-3xl m-2" />
                            <NavCard to="./nominations" text="Ernannte Brüder" icon="pi pi-tags pi-user text-3xl m-2" />
                        </div>
                    }
                ></Route>
                <Route path="congregations" element={<CongregationsPage />} />
                <Route path="brothers/*" element={<BrothersPage />} />
                <Route path="schedules/*" element={<SchedulesPage />} />
                <Route path="nominations" element={<NominationsPage />} />
                <Route path="nominationItems" element={<NominationItemsPage />} />
            </Routes>

            <Outlet />
        </div>
    );
};

export { OptionPage };
