import { useEffect, useState } from "react";
import { congregationService } from "../../services/congregation";
import { Congregation } from "@cos/core";
import { CongregationTable } from "./CongregationTable";

const CongregationsPage = () => {
    const [data, setData] = useState<Congregation[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const congregations = await congregationService.findAll();
            setData(congregations);
        };
        fetchData();
    }, []);

    const handleOnChanged = (congregation: Congregation) => {
        const newData = data.map(y => (y.congregationId === congregation.congregationId ? congregation : y));
        setData(newData);
    };

    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row">
                <CongregationTable congregations={data} allowEdit={true} onChanged={handleOnChanged} />
            </div>
        </div>
    );
};

export { CongregationsPage };
