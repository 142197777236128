import {
    AddNewAssignmentCommandData,
    AssignBrotherCommandData,
    Assignment,
    ChangeAssignmentCommandData,
    Command,
    ExchangeBrotherCommandData,
    RemoveAssignmentCommandData,
    RevokeBrotherCommandData,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/assignments`;

const assignmentService = {
    findAll: async (scheduleId: string): Promise<Assignment[]> => {
        const { data } = await api.get(`${API_URL}/bySchedule/${scheduleId}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findByBrotherId: async (brotherId: string): Promise<Assignment[]> => {
        const { data } = await api.get(`${API_URL}/byBrother/${brotherId}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (assignmentId: string): Promise<Assignment | null> => {
        const { data } = await api.get(`${API_URL}/${assignmentId}`);
        if (data.success) {
            return data.data;
        }
        return null;
    },
    addNewAssignment: async (cmd: Command<AddNewAssignmentCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeAssignment: async (cmd: Command<ChangeAssignmentCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeAssignment: async (cmd: Command<RemoveAssignmentCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    assignBrother: async (cmd: Command<AssignBrotherCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    revokeBrother: async (cmd: Command<RevokeBrotherCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    exchangeBrother: async (cmd: Command<ExchangeBrotherCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { assignmentService };
