import { useNavigate } from "react-router-dom";

export const NavCard = (props: { to: string; text: string; icon: string }) => {
    const navigate = useNavigate();

    const { to, text, icon } = props;

    const handleClick = () => {
        navigate(to);
    };

    return (
        <div
            className="flex flex-row  w-30rem h-5rem align-items-center  shadow-1 cursor-pointer m-2 p-2 surface-50"
            onClick={handleClick}
        >
            <i className={icon} />
            <div className="text-xl text-indigo-800 font-semibold m-2">{text}</div>
        </div>
    );
};
