import {
    AddNewCongregationCommandData,
    ChangeCongregationCommandData,
    Command,
    Congregation,
    QueryResult,
    RemoveCongregationCommandData,
} from "@cos/core";
import api from "../api/api";
import appConfig from "../app.config";

const apiBaseUrl = appConfig.apiBaseUrl;
const API_URL = `${apiBaseUrl}/congregations`;

const congregationService = {
    findAll: async (): Promise<Congregation[]> => {
        const { data } = await api.get(`${API_URL}`);

        if (data.success) {
            return data.data || [];
        }
        return [];
    },
    findById: async (congregationId: string): Promise<Congregation | null> => {
        const result: QueryResult<Congregation> = await api.get(`${API_URL}/${congregationId}`);
        if (result.success) {
            return result.data;
        }
        return null;
    },
    addNewCongregation: async (cmd: Command<AddNewCongregationCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    changeCongregation: async (cmd: Command<ChangeCongregationCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
    removeCongregation: async (cmd: Command<RemoveCongregationCommandData>) => {
        return await api.post(`${API_URL}`, cmd);
    },
};

export { congregationService };
