import { useEffect, useState } from "react";
import { SchedulePart } from "@cos/core";
import { SchedulePartTable } from "./SchedulePartTable";
import { useParams } from "react-router-dom";
import { schedulePartService } from "../../../services/schedulePart";

const SchedulePartsPage = () => {
    const params = useParams();
    const [data, setData] = useState<SchedulePart[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            if (params.scheduleId) {
                const data = await schedulePartService.findAll(params.scheduleId);
                setData(data);
            }
        };
        fetchData();
    }, []);

    const handleChanged = (schedulePart: SchedulePart) => {
        const newData = data.map(y => (y.schedulePartId === schedulePart.schedulePartId ? schedulePart : y));
        setData(newData);
    };

    return (
        <div className="flex flex-column m-2">
            <div className="flex flex-row">
                <SchedulePartTable scheduleParts={data} allowEdit={true} onChanged={handleChanged} />
            </div>
        </div>
    );
};

export { SchedulePartsPage };
