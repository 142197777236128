import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

const DatatableHeader = (props: {
    text: string;
    filter: string;
    onAdd?: () => void;
    onFilter: (value: string) => void;
    readonly?: boolean;
}) => {
    return (
        <div className="flex flex-w justify-content-between align-content-center h-3rem">
            {!props.readonly && (
                <Button icon="pi pi-plus" label="Neu" className="p-button-text" onClick={props.onAdd} />
            )}
            <h3 className="flex align-items-center">{props.text}</h3>
            <div className="inline-flex align-items-center">
                <span className="hidden md:block ">
                    <i className="pi pi-search mr-2"></i>
                    <InputText className="mr-2" value={props.filter} onChange={e => props.onFilter(e.target.value)} />
                </span>
            </div>
        </div>
    );
};

export { DatatableHeader };
