import {
    AssignBrotherCommandData,
    Assignment,
    AssignmentCommands,
    Brother,
    ChangeAssignmentCommandData,
    Command,
    ExchangeBrotherCommandData,
    Nomination,
    RevokeBrotherCommandData,
    Schedule,
} from "@cos/core";
import { format } from "date-fns";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import _ from "lodash";
import { Nullable } from "primereact/ts-helpers";
import { ExchangeDialog } from "./ExchangeDialog";
import { useState } from "react";
import { assignmentService } from "../../services/assignment";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

type AssignmentCardProps = {
    schedule: Nullable<Schedule>;
    assignment: Assignment;
    nominations: Nomination[] | null;
    onAssigned: (assignment: Assignment | null) => void;
    onChanged: () => void;
};
const AssignmentCard = (props: AssignmentCardProps) => {
    const user: Brother = JSON.parse(localStorage.getItem("user") || "");
    const [showExchange, setShowExchange] = useState(false);

    return (
        <Card
            title={`${format(props.assignment.start, "dd.MM.yyyy HH:mm")} - ${format(props.assignment.end, "dd.MM.yyyy HH:mm")} `}
            subTitle={props.assignment.part.name}
            className="border shadow-1 xl:w-3 lg:w-4 md:w-5 sm:w-12 m-1"
            footer={<CardFooter user={user} {...props} />}
            pt={{
                body: {
                    className: "h-full",
                },
            }}
        >
            <AssignTemplate
                assignment={props.assignment}
                nominations={props.nominations}
                user={user}
                schedule={props.schedule || null}
                onAssigned={props.onAssigned}
                onExchange={() => setShowExchange(true)}
            />
            <ExchangeDialog
                brotherId={user.brotherId}
                assignment={props.assignment}
                nominations={props.nominations || []}
                visible={showExchange}
                onHide={() => {
                    setShowExchange(false);
                    props.onChanged();
                }}
            ></ExchangeDialog>

            <div className="mt-1 h-full">
                {_.orderBy(
                    props.assignment?.assignedTo?.filter(y => y.brother.brotherId !== null).map(y => y.brother),
                    ["lastname", "firstname"],
                ).map(y => {
                    if (user?.brotherId === y.brotherId) {
                        return (
                            <div key={y.brotherId} className="flex flex-column align-items-start">
                                <div className="text-primary text-lg font-bold">{`${y.lastname}, ${y.firstname}`}</div>
                                <div className="text-xs font-italic ml-2">
                                    {props.assignment.assignedTo.find(x => x.brother.brotherId === y.brotherId)?.text}{" "}
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={y.brotherId} className="flex flex-column align-items-start">
                                <div>{`${y.lastname}, ${y.firstname}`}</div>
                                <div className="text-xs font-italic ml-2">
                                    {props.assignment.assignedTo.find(x => x.brother.brotherId === y.brotherId)?.text}
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </Card>
    );
};

type AssignTemplateProps = {
    assignment: Assignment;
    nominations: Nomination[] | null;
    user: Brother;
    schedule: Nullable<Schedule>;
    onAssigned: (assignment: Assignment | null) => void;
    onExchange: () => void;
};
const AssignTemplate = (props: AssignTemplateProps) => {
    const assigned = props.assignment?.assignedTo?.filter(y => y.brother.brotherId !== null).length;
    const open = props.assignment.max - assigned;

    const isNominate = props.nominations?.some(
        n => n.brother.brotherId === props.user.brotherId && n.schedule?.scheduleId === props.schedule?.scheduleId,
    );
    const isAssigned = props.assignment?.assignedTo.some(
        assigendBrother => assigendBrother.brother.brotherId === props.user.brotherId,
    );

    return (
        <div className="flex flex-row justify-content-between align-items-center">
            {`${assigned}/${props.assignment.max}`}
            {isNominate && !isAssigned && open > 0 && (
                <Button
                    className="p-button-text"
                    icon="pi pi-user-plus text-xl font-bold"
                    onClick={() => props.onAssigned(props.assignment)}
                ></Button>
            )}
            {isAssigned && (
                <Button
                    className="p-button-text"
                    icon="pi pi-users text-xl font-bold"
                    onClick={props.onExchange}
                ></Button>
            )}
        </div>
    );

    //return <div className="flex flex-row justify-content-start">{`${assigned}/${props.assignment.max}`}</div>;
};

const CardFooter = (props: {
    user: Brother;
    schedule: Nullable<Schedule>;
    assignment: Assignment;
    nominations: Nomination[] | null;
    onAssigned: (assignment: Assignment | null) => void;
    onChanged: () => void;
}) => {
    const roles: { scheduleId: string; role: string }[] = JSON.parse(localStorage.getItem("user") || "")?.roles;
    const scheduleRole = roles.find(r => r.scheduleId === props.schedule?.scheduleId && r.role === "ADMIN");

    const [showAdd, setShowAdd] = useState(false);
    const [showExchange, setShowExchange] = useState(false);
    const [showRevoke, setShowRevoke] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [currentBrother, setCurrentBrother] = useState<Brother>();
    const [newBrother, setNewBrother] = useState<Brother>();
    const [exchangeText, setExchangeText] = useState<string>("");
    const [addText, setAddText] = useState<string>("");
    const [editText, setEditText] = useState<string>("");

    const handleAdd = async () => {
        const cmd: Command<AssignBrotherCommandData> = {
            type: AssignmentCommands.AssignBrother,
            data: {
                assignmentId: props.assignment?.assignmentId || "",
                brotherId: newBrother?.brotherId || "",
                nominationId: "",
                text: addText || "",
            },
        };

        await assignmentService.assignBrother(cmd);
        setCurrentBrother(undefined);
        setNewBrother(undefined);
        setAddText("");
        props.onChanged();
        setShowAdd(false);
    };

    const handleIncrement = async () => {
        const data = props.assignment;
        const cmd: Command<ChangeAssignmentCommandData> = {
            type: AssignmentCommands.ChangeAssignment,
            data: {
                assignmentId: data.assignmentId,
                start: data.start,
                end: data.end,
                min: data.min || 1,
                max: (data.max || 1) + 1,
                schedulePartId: data.part.schedulePartId,
            },
        };

        await assignmentService.changeAssignment(cmd);
        props.onChanged();
    };

    const handleDecrement = async () => {
        const data = props.assignment;
        const cmd: Command<ChangeAssignmentCommandData> = {
            type: AssignmentCommands.ChangeAssignment,
            data: {
                assignmentId: data?.assignmentId || "",
                start: data?.start || "",
                end: data?.end || "",
                min: data?.min || 1,
                max: (data?.max || 1) - 1,
                schedulePartId: data?.part?.schedulePartId || "",
            },
        };

        await assignmentService.changeAssignment(cmd);
        props.onChanged();
    };

    const handleExchange = async () => {
        const cmd: Command<ExchangeBrotherCommandData> = {
            type: AssignmentCommands.ExchangeBrother,
            data: {
                assignmentId: props.assignment.assignmentId,
                brotherId: currentBrother?.brotherId || "",
                newBrotherId: newBrother?.brotherId || "",
                nominationId: null,
                text: exchangeText || "",
            },
        };
        await assignmentService.exchangeBrother(cmd);
        setCurrentBrother(undefined);
        setNewBrother(undefined);
        setExchangeText("");
        props.onChanged();
        setShowExchange(false);
    };

    const handleRevoke = async () => {
        const cmd: Command<RevokeBrotherCommandData> = {
            type: AssignmentCommands.RevokeBrother,
            data: {
                assignmentId: props.assignment?.assignmentId || "",
                brotherId: currentBrother?.brotherId || "",
            },
        };
        await assignmentService.revokeBrother(cmd);

        setCurrentBrother(undefined);
        setNewBrother(undefined);
        props.onChanged();
        setShowRevoke(false);
    };

    const handleEdit = async () => {
        const cmd: Command<ExchangeBrotherCommandData> = {
            type: AssignmentCommands.ExchangeBrother,
            data: {
                assignmentId: props.assignment.assignmentId,
                brotherId: currentBrother?.brotherId || "",
                newBrotherId: currentBrother?.brotherId || "",
                nominationId: null,
                text: editText || "",
            },
        };
        await assignmentService.exchangeBrother(cmd);
        setCurrentBrother(undefined);
        setEditText("");
        props.onChanged();
        setShowEdit(false);
    };

    if (!scheduleRole) return null;

    return (
        <>
            <Dialog
                className="md:w-6 sm:w-10"
                visible={showAdd}
                header="Zuteilung hinzufügen"
                onHide={() => {
                    props.onChanged();
                    setShowAdd(false);
                }}
            >
                <div className="field">
                    <label>Zuteilung an:</label>
                    <Dropdown
                        id="newBrother"
                        className="w-full"
                        value={newBrother}
                        onChange={e => setNewBrother(e.value)}
                        itemTemplate={option => (
                            <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>
                        )}
                        valueTemplate={option => (
                            <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>
                        )}
                        optionValue="brother"
                        optionLabel="brother.lastname"
                        options={_.orderBy(
                            props.nominations?.filter(
                                y =>
                                    !props.assignment.assignedTo.some(x => x.brother.brotherId === y.brother.brotherId),
                            ),
                            ["brother.lastname", "brother.firstname"],
                        )}
                    />
                </div>
                <div className="field">
                    <label>Bemerkung:</label>
                    <InputText
                        className="block w-full"
                        value={exchangeText}
                        onChange={e => setExchangeText(e.target.value)}
                    />
                </div>

                <div className="flex justify-content-end mt-3">
                    <Button className="p-button-text mr-2" label="Abbrechen" onClick={() => setShowAdd(false)} />
                    <Button className="p-button-danger" label="Ok" icon="pi pi-check" onClick={handleAdd} />
                </div>
            </Dialog>
            <Dialog
                className="md:w-6 sm:w-10"
                visible={showExchange}
                header="Zuteilung ändern"
                onHide={() => {
                    props.onChanged();
                    setShowExchange(false);
                }}
            >
                <div className="field">
                    <label>Aktuell zugewiesen an:</label>
                    <Dropdown
                        id="currentBrother"
                        className="w-full"
                        value={currentBrother}
                        onChange={e => setCurrentBrother(e.value)}
                        itemTemplate={option => (
                            <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>
                        )}
                        valueTemplate={option => (
                            <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>
                        )}
                        optionValue="brother"
                        optionLabel="brother.lastname"
                        options={_.orderBy(props.assignment.assignedTo, ["brother.lastname", "brother.firstname"])}
                    />
                </div>
                <div className="field">
                    <label>Übergeben an:</label>
                    <Dropdown
                        id="newBrother"
                        className="w-full"
                        value={newBrother}
                        onChange={e => setNewBrother(e.value)}
                        itemTemplate={option => (
                            <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>
                        )}
                        valueTemplate={option => (
                            <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>
                        )}
                        optionValue="brother"
                        optionLabel="brother.lastname"
                        options={_.orderBy(
                            props.nominations?.filter(
                                y =>
                                    !props.assignment.assignedTo.some(x => x.brother.brotherId === y.brother.brotherId),
                            ),
                            ["brother.lastname", "brother.firstname"],
                        )}
                    />
                </div>
                <div className="field">
                    <label>Bemerkung:</label>
                    <InputText
                        className="block w-full"
                        value={exchangeText}
                        onChange={e => setExchangeText(e.target.value)}
                    />
                </div>
                <div className="flex justify-content-end mt-3">
                    <Button className="p-button-text mr-2" label="Abbrechen" onClick={() => setShowExchange(false)} />
                    <Button className="p-button-success" label="Ok" icon="pi pi-check" onClick={handleExchange} />
                </div>
            </Dialog>
            <Dialog
                className="md:w-6 sm:w-10"
                visible={showRevoke}
                header="Zuteilung löschen"
                onHide={() => {
                    props.onChanged();
                    setShowRevoke(false);
                }}
            >
                <div className="field">
                    <label>Zuteilung an:</label>
                    <Dropdown
                        id="currentBrother"
                        className="w-full"
                        value={currentBrother}
                        onChange={e => setCurrentBrother(e.value)}
                        itemTemplate={option => (
                            <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>
                        )}
                        valueTemplate={option => (
                            <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>
                        )}
                        optionValue="brother"
                        optionLabel="brother.lastname"
                        options={_.orderBy(props.assignment.assignedTo, ["brother.lastname", "brother.firstname"])}
                    />
                </div>

                <div className="flex justify-content-end mt-3">
                    <Button className="p-button-text mr-2" label="Abbrechen" onClick={() => setShowRevoke(false)} />
                    <Button className="p-button-danger" label="Ok" icon="pi pi-check" onClick={handleRevoke} />
                </div>
            </Dialog>
            <Dialog
                className="md:w-6 sm:w-10"
                visible={showEdit}
                header="Hinweis ändern"
                onHide={() => {
                    props.onChanged();
                    setShowEdit(false);
                }}
            >
                <div className="field">
                    <label>Zuteilung an:</label>
                    <Dropdown
                        id="currentBrother"
                        className="w-full"
                        value={currentBrother}
                        onChange={e => {
                            setEditText(
                                props.assignment.assignedTo.find(y => y.brother.brotherId === e.value.brotherId)
                                    ?.text || "",
                            );
                            setCurrentBrother(e.value);
                        }}
                        itemTemplate={option => (
                            <span>{`${option.brother?.lastname}, ${option.brother?.firstname}`}</span>
                        )}
                        valueTemplate={option => (
                            <span>{`${option?.brother?.lastname}, ${option?.brother?.firstname}`}</span>
                        )}
                        optionValue="brother"
                        optionLabel="brother.lastname"
                        options={_.orderBy(props.assignment.assignedTo, ["brother.lastname", "brother.firstname"])}
                    />
                </div>

                <div className="field">
                    <label>Bemerkung:</label>
                    <InputText className="block w-full" value={editText} onChange={e => setEditText(e.target.value)} />
                </div>
                <div className="flex justify-content-end mt-3">
                    <Button className="p-button-text mr-2" label="Abbrechen" onClick={() => setShowEdit(false)} />
                    <Button className="p-button-success" label="Ok" icon="pi pi-check" onClick={handleEdit} />
                </div>
            </Dialog>

            <hr />
            <div className="flex justify-content-between align-items-end">
                <div className="flex">
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-plus"
                        onClick={handleIncrement}
                    ></Button>
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-minus"
                        onClick={handleDecrement}
                    ></Button>
                </div>
                <div>
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-user-plus"
                        onClick={() => setShowAdd(true)}
                    ></Button>
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-users"
                        onClick={() => setShowExchange(true)}
                    ></Button>
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-user-minus"
                        onClick={() => setShowRevoke(true)}
                    ></Button>
                    <Button
                        className="p-button-outlined p-button-secondary mx-1"
                        icon="pi pi-user-edit"
                        onClick={() => setShowEdit(true)}
                    ></Button>
                </div>
            </div>
        </>
    );
};

export { AssignmentCard };
